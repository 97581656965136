import { BgImage } from "./BgImage";
import { CurvedText } from "./CurvedText";
import { RotateImg } from "./RotateImg";
import { slideNames } from "./types";

export function RotatingBlock(props: {
  active: slideNames;
  background: string;
  centerImg: string;
  text: string | React.ReactNode;
  name: slideNames;
  offset: number;
  displayName: string;
  setActive: (arg1: slideNames) => void;
}) {
  const className = props.active === props.name ? "active" : "";

  return (
    <>
      <CurvedText
        text={props.name}
        displayText={props.displayName}
        selected={props.active}
        offset={props.offset}
        onClick={() => props.setActive(props.name)}
      />
      <BgImage
        className={className}
        $url={props.background}
        title={props.name}
        text={props.text}
      />
      <RotateImg $url={props.centerImg} className={className} />
    </>
  );
}
