import styled from "styled-components";
import { useContattiQuery } from "../../generated/graphql";

const IconBlock = styled.div`
  display: flex;

  align-items: center;
  flex-direction: column;
  text-align: center;
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #a48559;
    margin: 0;
  }
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
  margin: 10px;
`;

const ServicesBlock = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
  padding: 20px;
  background-color: #f8f7f7;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export function ContactsServices() {
  const { data } = useContattiQuery();
  return (
    <ServicesBlock>
      <IconBlock>
        <Icon src="contacts_icons/padel.webp" alt="icon" />
        <h2>PADEL ARENA</h2>
        <p>{data?.contatti?.padelArenaDettagli}</p>
      </IconBlock>
      <IconBlock>
        <Icon src="contacts_icons/rest.webp" alt="icon" />
        <h2>I TIGLI</h2>
        <h2>Restaurant, Bistrot, Lounge Bar</h2>
        <p>{data?.contatti?.iTigliDettagli}</p>
      </IconBlock>
      <IconBlock>
        <Icon src="contacts_icons/services.webp" alt="icon" />
        <h2>SERVIZI</h2>
        <p>{data?.contatti?.serviziDettagli}</p>
      </IconBlock>
      <IconBlock>
        <Icon src="contacts_icons/pool.webp" alt="icon" />
        <h2>PISCINA</h2>
        <p>{data?.contatti?.piscinaDettagli}</p>
      </IconBlock>
    </ServicesBlock>
  );
}
