import { useInView, animated } from "@react-spring/web";
import React from "react";
import { useMainDescriptionsQuery } from "../generated/graphql";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Logo = styled.div<{ $url?: string }>`
  width: 200px;
  height: 300px;
  background-size: contain;
  background-image: ${(props) => `url(${props.$url})`};
  background-repeat: no-repeat;
  padding: 50px;
  background-position: center;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const Title = styled(animated.div)`
  color: #a48559;
  font-size: 1.3em;
  @media screen and (max-width: 800px) {
    text-align: center;
  }
`;

const Container = styled.div<{ $reverse?: boolean }>`
  flex-direction: ${(props) => (props.$reverse ? "row-reverse" : "row")};
  display: flex;
  margin-bottom: 2em;
  gap: 20px;
  line-height: 1.5;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    padding: 40px;
  }
  &.noImg {
    padding: 40px;
    padding-bottom: 0;
    & > ${Logo} {
      display: none;
    }
    & ${Title} {
      text-align: center;
    }
  }
`;

const Text = styled(animated.div)<{ $justifyText?: boolean }>`
  max-width: 800px;
  p {
    margin-bottom: 0;
    text-align: ${(props) => (props.$justifyText ? "justify" : "auto")};
  }
`;

export function TopDescription(props: {
  justifyContent?: boolean;
  reverse?: boolean;
  logoImg?: string;
  id: string;
}) {
  const { data } = useMainDescriptionsQuery({
    variables: { id: props.id },
  });

  const [ref, springs] = useInView(() => ({
    from: {
      opacity: 0,
      y: 100,
    },
    to: {
      opacity: 1,
      y: 0,
    },
  }));

  return (
    <Container
      $reverse={props.reverse}
      className={props.logoImg ? "" : "noImg"}
    >
      <div>
        <Title ref={ref} style={springs}>
          {data?.descrizionePrincipale?.titolo}
        </Title>
        <br />
        <Text ref={ref} style={springs} $justifyText={props.justifyContent}>
          {documentToReactComponents(
            data?.descrizionePrincipale?.descrizione?.json
          )}
        </Text>
      </div>

      <Logo $url={props.logoImg} />
    </Container>
  );
}
