import { animated, useInView } from "@react-spring/web";
import styled from "styled-components";
import { usePadelQuery } from "../../generated/graphql";

const Title = styled.div`
  font-size: 2em;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 100px;
  font-weight: 400;
`;

const Container = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 20px;
  }
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  margin-bottom: 100px;
  gap: 50px;
  padding: 50px;

  margin-bottom: 100px;
`;
const ImgBlock = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 500px;
  align-items: center;
  & > img {
    display: inline-block;

    width: 400px;
    height: 600px;
    margin-bottom: 10px;

    object-fit: cover;
    @media screen and (max-width: 800px) {
      width: 100vw;
      height: calc(100vw * 1.6);
      max-width: 600px;
    }
  }
  & > h1 {
    font-size: 1.3;
  }
`;

function InstructorBlock(props: { name: string; img: string; bio: string }) {
  const [ref, springs] = useInView(() => ({
    from: {
      transform: "rotateY(90deg)",
    },

    to: {
      transform: "rotateY(0deg)",
    },

    config: {
      delay: 5000,
      tension: 400, // How much tension is on the spring
      mass: 3, // The mass of the spring
      velocity: 5,
    },
  }));
  return (
    <ImgBlock>
      <animated.img src={props.img} ref={ref} style={springs}></animated.img>
      <h1>{props.name}</h1>
      {props.bio}
    </ImgBlock>
  );
}
export function Instructor() {
  const { data } = usePadelQuery();

  return (
    <>
      <Title>{data?.istrutturi?.titolo}</Title>
      <Container>
        <InstructorBlock
          bio={data?.istrutturi?.christianBio || ""}
          img="instructor/christian.webp"
          name="Christian Carena"
        />
        <InstructorBlock
          bio={data?.istrutturi?.robertaBio || ""}
          img="instructor/roberta.webp"
          name="Roberta Zandarin"
        />
      </Container>
    </>
  );
}
