import styled from "styled-components";
import { useIconeQuery } from "../../generated/graphql";

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 700px) {
    padding: 0 2rem;
  }
`;
const IconsGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-row-gap: 40px;
  grid-column-gap: 35px;
  width: 100%;
  max-width: 1200px;
  justify-content: space-evenly;

  & > p {
    width: 100%;
    grid-column: 1 / 5;
    flex: 1;
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: auto auto auto;
    & > p {
      grid-column: 1 / 4;
    }
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: auto auto;
    grid-row-gap: 30px;
    grid-column-gap: 10px;
    & > p {
      grid-column: 1 / 3;
    }
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: auto;
    grid-row-gap: 30px;
    grid-column-gap: 10px;
    & > p {
      grid-column: 1 / 2;
    }
  }
`;
const IconFlex = styled.div`
  display: flex;

  flex-direction: row;
  height: 20px;

  align-items: center;
  grid-column-gap: 10px;
  img {
    height: 30px;
    width: 30px;
    object-fit: contain;
    object-position: center;
  }
`;
function IconBlock(props: { iconImg?: string; text: string }) {
  return (
    <IconFlex>
      {props.iconImg && <img src={props.iconImg} alt="icon" />}
      <p>{props.text}</p>
    </IconFlex>
  );
}
export function IncludedInRooms() {
  const { data } = useIconeQuery();
  return (
    <FlexContainer>
      <IconsGrid>
        <p>{data?.hotelPage?.camereIncludono}</p>
        <IconBlock
          iconImg="/hotelImg/smallIcons/armchair.webp"
          text={data?.icone?.arrediDiDesign || ""}
        />
        <IconBlock
          iconImg="/hotelImg/smallIcons/coffee.webp"
          text={data?.icone?.nespressoConBollitore || ""}
        />
        <IconBlock
          iconImg="/hotelImg/smallIcons/vault.webp"
          text={data?.icone?.cassaforte || ""}
        />
        <IconBlock iconImg="/hotelImg/smallIcons/gym.webp" text="Palestra" />
        <IconBlock
          iconImg="/hotelImg/smallIcons/clean.webp"
          text={data?.icone?.cleaningService || ""}
        />
        <IconBlock
          iconImg="/hotelImg/smallIcons/pillow.webp"
          text={data?.icone?.cusciniEPiuminiKingSize || ""}
        />
        <IconBlock
          iconImg="/hotelImg/smallIcons/soap.webp"
          text={data?.icone?.prodottiDaBagnoRitualsCosmetics || ""}
        />
        <IconBlock iconImg="/hotelImg/smallIcons/wifi.webp" text="Free Wifi" />
        <IconBlock
          iconImg="/hotelImg/smallIcons/ac.webp"
          text={data?.icone?.ariaCondizionata || ""}
        />
        <IconBlock
          iconImg="/hotelImg/smallIcons/carry.webp"
          text={data?.icone?.roomService || ""}
        />
        <IconBlock
          iconImg="/hotelImg/smallIcons/phone.webp"
          text={data?.icone?.telefonoFisso || ""}
        />
        <IconBlock
          iconImg="/hotelImg/smallIcons/hairdryer.webp"
          text={data?.icone?.phonProfessionaleParlux || ""}
        />
        <IconBlock iconImg="/hotelImg/smallIcons/fridge.webp" text="Frigobar" />
        <IconBlock
          iconImg="/hotelImg/smallIcons/tv.webp"
          text={data?.icone?.tv50ASchermoPiatto || ""}
        />
        <IconBlock
          iconImg="/hotelImg/smallIcons/sink.webp"
          text={data?.icone?.bagnoInMarmo || ""}
        />
        <IconBlock
          iconImg="/hotelImg/smallIcons/poolBed.webp"
          text={data?.icone?.lettini || ""}
        />
        <IconBlock iconImg="/hotelImg/smallIcons/desk.webp" text="Desk" />
        <IconBlock
          iconImg="/hotelImg/smallIcons/wardrobe.webp"
          text={data?.icone?.cabinaArmadio || ""}
        />
      </IconsGrid>
    </FlexContainer>
  );
}
