import { animated, useInView } from "@react-spring/web";
import { useDrag, useScroll } from "@use-gesture/react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FullScreenImg } from "./FullScreenImg";
import { useGalleriaQuery } from "../generated/graphql";

const ContainerPic = styled(animated.div)`
  white-space: nowrap;
  overflow: hidden;
  scroll-behavior: smooth;
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
  touch-action: none;
`;
const ImgContainer = styled.span`
  position: relative;
  display: inline-block;
  margin: 0 10px;
  span {
    background-color: var(--gold);
    display: block;
    color: white;
    height: 50px;
    width: 100%;
    margin-top: -4px;
    text-align: center;
    line-height: 50px;
  }
`;

const Element = styled.img<{ name?: string }>`
  object-fit: contain;

  height: 300px;
  display: inline-block;

  position: relative;
`;

const BlankElement = styled.div`
  width: 500px;

  height: 300px;
  display: inline-block;
  margin: 10px;
`;

const DotContainer = styled.div<{ $nrImg: number }>`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;

  @media screen and (max-width: 800px) {
    gap: ${(props) => (props.$nrImg > 15 ? "3px" : "15px")};
  }
  & > div {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #d9d9d9;
    user-select: none; /* Standard syntax */

    cursor: pointer;
    @media screen and (max-width: 800px) {
      height: calc(70vw / ${(props) => props.$nrImg});
      width: calc(70vw / ${(props) => props.$nrImg});
      max-width: 15px;
      max-height: 15px;
    }
    &.active {
      background-color: #747272;
    }
  }
`;

const scrollToElement = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }
};

export function Scroller(props: {
  len: number;
  name: string;
  names?: string[];
}) {
  const [currPic, setCurrPic] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [fullScreenImg, setFullScreenImg] = useState<false | string>(false);

  const pics = Array.from({ length: props.len });
  useEffect(() => {
    if (loaded) scrollToElement("scroll" + props.name + currPic.toString());
    setLoaded(true);
  }, [currPic]);

  const bind = useDrag(
    ({ down, first, direction }) => {
      down && first && scroll(direction[0]);
    },

    {
      axis: "x",
    }
  );

  function scroll(direction: number) {
    if (currPic - direction < 0 || currPic + direction * -1 > props.len) return;
    direction === 1 ? setCurrPic(currPic - 1) : setCurrPic(currPic + 1);
  }
  const [ref, springs] = useInView(() => ({
    from: {
      opacity: 0,
      y: 100,
    },
    to: {
      opacity: 1,
      y: 0,
    },
  }));

  return (
    <Container {...bind()}>
      {fullScreenImg && (
        <FullScreenImg
          src={fullScreenImg}
          onclose={() => setFullScreenImg(false)}
        />
      )}
      <ContainerPic ref={ref} style={springs}>
        {pics.map((_, index) => (
          <ImgContainer
            key={index + "foto"}
            onClick={() => setFullScreenImg(`${props.name}${index}.webp`)}
          >
            <Element
              loading="lazy"
              id={"scroll" + props.name + index.toString()}
              onClick={() => setCurrPic(index)}
              src={`${props.name}${index}.webp`}
            />
            {props.names && (
              <span>
                <b>{props.names[index]}</b>
              </span>
            )}
          </ImgContainer>
        ))}
        <BlankElement />
      </ContainerPic>
      <DotContainer $nrImg={props.len}>
        {pics.map((_, index) => (
          <div
            key={index + "pallino"}
            className={currPic === index ? "active" : ""}
            onClick={() => setCurrPic(index)}
          />
        ))}
      </DotContainer>
    </Container>
  );
}

export function ApiScroller(props: { id: string; names?: string[] }) {
  const { data } = useGalleriaQuery({ variables: { id: props.id } });
  const [currPic, setCurrPic] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [fullScreenImg, setFullScreenImg] = useState<false | string>(false);
  const len = data?.galleria?.fotoCollection?.total || 0;
  const name = data?.galleria?.titolo || "";
  const pics = data?.galleria?.fotoCollection?.items || [];
  useEffect(() => {
    if (loaded) scrollToElement("scroll" + name + currPic.toString());
    setLoaded(true);
  }, [currPic]);

  const bind = useDrag(
    ({ down, first, direction }) => {
      down && first && scroll(direction[0]);
    },

    {
      axis: "x",
    }
  );

  function scroll(direction: number) {
    if (currPic - direction < 0 || currPic + direction * -1 > len) return;
    direction === 1 ? setCurrPic(currPic - 1) : setCurrPic(currPic + 1);
  }
  const [ref, springs] = useInView(() => ({
    from: {
      opacity: 0,
      y: 100,
    },
    to: {
      opacity: 1,
      y: 0,
    },
  }));

  return (
    <Container {...bind()}>
      {fullScreenImg && (
        <FullScreenImg
          src={fullScreenImg}
          onclose={() => setFullScreenImg(false)}
        />
      )}
      <ContainerPic ref={ref} style={springs}>
        {pics.map((pic, index) => (
          <ImgContainer
            key={index + "foto"}
            onClick={() =>
              setFullScreenImg(
                "https://thepadelresort.it/.netlify/images?url=" +
                  pic?.url +
                  "&w=1000" || ""
              )
            }
          >
            <Element
              loading="lazy"
              id={"scroll" + name + index.toString()}
              onClick={() => setCurrPic(index)}
              src={
                "https://thepadelresort.it/.netlify/images?url=" +
                  pic?.url +
                  "&w=600" || ""
              }
              height="300px"
              max-width="600px"
              min-width="100px"
            />
            {props.names && (
              <span>
                <b>{props.names[index]}</b>
              </span>
            )}
          </ImgContainer>
        ))}
        <BlankElement />
      </ContainerPic>
      <DotContainer $nrImg={len}>
        {pics.map((_, index) => (
          <div
            key={index + "pallino"}
            className={currPic === index ? "active" : ""}
            onClick={() => setCurrPic(index)}
          />
        ))}
      </DotContainer>
    </Container>
  );
}
