import styled from "styled-components";
import { RotateImg } from "./RotateImg";

export const TextSphere = styled.div`
  display: flex;
  top: 100%;
  transform-origin: top left;
  left: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  text-align: center;
  justify-content: center;
  transform: rotate(109deg) translate(41px, var(--sphere-positon));
  transition-timing-function: var(--timing-function);

  transition-duration: var(--trans-duration);

  & div {
    margin-top: -25px;
  }
`;
export const ExternalCircle = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: var(--radius);
  height: var(--height);
  border-top-left-radius: var(--corner-radius);
  border-top-right-radius: var(--corner-radius);

  border: var(--border-width) solid white;
  position: absolute;
  border-bottom: 0;
`;

export const CircleContainer = styled.div`
  padding-bottom: 5px;
  --radius: 700px;
  @media (max-width: 800px) {
    --radius: 300px;
  }
  --inner-radius: calc(var(--radius) - 100px);
  --border-width: 2px;
  --height: calc(var(--radius) / 2);
  --inner-height: calc(var(--inner-radius) / 2);
  --corner-radius: calc(var(--radius) + var(--border-width));
  --inner-corner-radius: calc(var(--inner-radius) + var(--border-width));
  --sphere-positon: calc(var(--radius) / 2 - 10px);
  @media (max-width: 800px) {
    --radius: 300px;
    --sphere-positon: calc(var(--radius) / 2 - 17px);
  }
  width: var(--radius);
  height: var(--height);
  border-top-left-radius: var(--corner-radius);
  border-top-right-radius: var(--corner-radius);

  position: relative;

  z-index: 1;
  &::after {
    content: "";

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: var(--inner-radius);
    height: var(--inner-height);
    border-top-left-radius: var(--inner-corner-radius);
    border-top-right-radius: var(--inner-corner-radius);

    border: var(--border-width) solid white;
    position: absolute;
    border-bottom: 0;
    margin-top: 25px;
    @media (max-width: 800px) {
      border-color: transparent;
    }
  }

  --Padel-deg: 111.3deg;
  --Ristorante-deg: 162.7deg;
  --Hotel-deg: 215.5deg;
  --Concessionaria-deg: 253.9deg;
  @media (max-width: 800px) {
    --Padel-deg: 121.8deg;
    --Ristorante-deg: 167.9deg;
    --Hotel-deg: 215.5deg;
    --Concessionaria-deg: 260.7deg;
  }
  &.Padel > ${TextSphere} {
    transform: rotate(var(--Padel-deg)) translate(41px, var(--sphere-positon));
  }
  &.Ristorante > ${TextSphere} {
    transform: rotate(var(--Ristorante-deg))
      translate(41px, var(--sphere-positon));
  }
  &.Hotel > ${TextSphere} {
    transform: rotate(var(--Hotel-deg)) translate(41px, var(--sphere-positon));
  }
  &.Piscina > ${TextSphere} {
    transform: rotate(var(--Concessionaria-deg))
      translate(41px, var(--sphere-positon));
  }

  &.Padel > ${RotateImg} {
    &::after {
      transform: translate(-50%, -50%) rotate(var(--Padel-deg));
    }
  }
  &.Ristorante > ${RotateImg} {
    &::after {
      transform: translate(-50%, -50%) rotate(var(--Ristorante-deg));
    }
  }
  &.Hotel > ${RotateImg} {
    &::after {
      transform: translate(-50%, -50%) rotate(var(--Hotel-deg));
    }
  }

  &.Piscina > ${RotateImg} {
    &::after {
      transform: translate(-50%, -50%) rotate(var(--Concessionaria-deg));
    }
  }
`;
