import styled from "styled-components";
import { animated } from "@react-spring/web";

const Container = styled(animated.div)<{ $url: string }>`
  position: absolute;
  z-index: -1;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  height: 600px;
  width: 100vw;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(0 0 0 / 54%), rgb(0 0 0 / 54%)),
    ${(props) => `url(${props.$url})`};
  @media screen and (max-width: 800px) {
    height: 450px;
  }

  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  color: white;
  text-shadow: 2px 2px black;
  font-size: 1.1em;
  padding-top: 60px;
  opacity: 0;
  & > p {
    max-width: var(--radius);
    word-wrap: break-word;
    white-space: pre-line;
    @media screen and (max-width: 800px) {
      font-size: 0.8em;
      text-shadow: 1px 1px black;
    }
  }
  &.active {
    opacity: 1;
  }
  transition-property: opacity;
  transition-timing-function: ease-in-out;

  transition-duration: var(--trans-duration);
`;
const Title = styled.div`
  font-size: 3em;
  text-transform: uppercase;
  @media screen and (max-width: 800px) {
    font-size: 2em;
  }
`;

export function BgImage(props: {
  title: string;
  text: string | React.ReactNode;
  $url: string;
  className: string;
}) {
  return (
    <Container {...props}>
      <Title>{props.title}</Title>

      {props.text}
    </Container>
  );
}
