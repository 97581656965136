import styled from "styled-components";

import { CircleContainer, ExternalCircle, TextSphere } from "./Circle";
import { useState } from "react";
import { slideNames } from "./types";

import { RotatingBlock } from "./RotatingBlock";

import { useWindowSize } from "@uidotdev/usehooks";
import { useGirellaQuery } from "../../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useControls } from "leva";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 410px;
  --timing-function: cubic-bezier(0.47, 1.64, 0.41, 0.8);
  --trans-duration: 400ms;
  @media screen and (min-width: 800px) {
    margin-top: 300px;
  }
`;

export function CurvedSlide() {
  const [active, setActive] = useState<slideNames>("Padel");
  const size = useWindowSize();
  const { data } = useGirellaQuery();

  /*   const { padel, ris, hotel, con, deg } = useControls({
    padel: {
      value: 44,
      min: 0,
      max: 900,
      step: 0.1,
    },
    ris: {
      value: 339.5,
      min: 0,
      max: 900,
      step: 0.1,
    },
    hotel: {
      value: 682,
      min: 0,
      max: 900,
      step: 0.1,
    },
    con: {
      value: 885,
      min: 0,
      max: 1200,
      step: 0.1,
    },
    deg: {
      value: 0,
      min: 0,
      max: 300,
      step: 0.1,
    },
  }); */

  const offSets =
    size.width && size.width > 800
      ? {
          padel: 44,
          ris: 339.5,
          hotel: 714.7,
          con: 949,
        }
      : {
          padel: 9.0,
          ris: 118.3,
          hotel: 280.6,
          con: 398.4,
        };
  return (
    <div>
      <Container>
        <CircleContainer className={active}>
          <RotatingBlock
            displayName={data?.traduzioniVarie?.hotel || ""}
            background="/rotSlide/HOTEL.webp"
            name="Hotel"
            active={active}
            setActive={setActive}
            centerImg="/rotSlide/bell.webp"
            offset={offSets.hotel}
            text={documentToReactComponents(data?.girella?.hotel?.json)}
          />

          <RotatingBlock
            displayName={data?.traduzioniVarie?.padel || ""}
            background="/rotSlide/padel.webp"
            name="Padel"
            centerImg="/rotSlide/ball.webp"
            offset={offSets.padel}
            text={documentToReactComponents(data?.girella?.padel?.json)}
            active={active}
            setActive={setActive}
          />
          <RotatingBlock
            displayName={data?.traduzioniVarie?.ristorante || ""}
            background="/rotSlide/risto.webp"
            name="Ristorante"
            centerImg="/rotSlide/pasta.webp"
            offset={offSets.ris}
            text={documentToReactComponents(data?.girella?.ristorante?.json)}
            active={active}
            setActive={setActive}
          />
          <RotatingBlock
            displayName={"Piscina"}
            background="/rotSlide/piscina.webp"
            name="Piscina"
            centerImg="/rotSlide/salvage.webp"
            offset={offSets.con}
            text={documentToReactComponents(
              data?.girella?.concessionaria?.json
            )}
            setActive={setActive}
            active={active}
          />
          <ExternalCircle />
          <TextSphere
            style={
              {
                //  transform: `rotate(${deg}deg) translate(41px, var(--sphere-positon))`,
              }
            }
          />
        </CircleContainer>
      </Container>
    </div>
  );
}
