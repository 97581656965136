import styled from "styled-components";
import { GoldButton } from "../../components/GoldButton";
import { useCamereQuery, useTraduzioniQuery } from "../../generated/graphql";

const RoomCard = styled.div`
  display: grid;

  height: 450px;
  background-color: white;
  margin: 1em;
  grid-auto-rows: auto;
  padding-bottom: 1em;
  width: 400px;
  max-width: 90vw;
  grid-template-columns: 1fr 1fr;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    grid-column: 1 / 3;
  }
  p {
    justify-items: center;
    text-align: center;
    line-height: 30px;
  }
`;
const CameraName = styled.p`
  margin-block-end: 0;
  height: fit-content;
  padding-bottom: 0.5rem;
  grid-column: 1 / 3;
  text-align: center;
  border-bottom: 1px solid var(--gold);
`;
const Button = styled(GoldButton)`
  margin-block-end: 0;
  height: fit-content;
  padding-bottom: 0.5rem;

  text-align: center;
  border-bottom: 1px solid var(--gold);
  padding: 0.5rem 0;
  width: auto;
  align-self: center;
  justify-self: center;
  font-size: 1rem;
`;
const ButtonInverted = styled(Button)`
  color: var(--gold);
  background-color: white;
  border: 1px solid var(--gold);
  &:hover {
    color: white;
  }
`;
const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;

  padding-bottom: 2rem;

  &::-webkit-scrollbar {
    width: 10px;
    border: 1px solid var(--gold);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--gold);
  }
`;
const RoomLink = styled.a`
  display: block;
  justify-self: center;
`;
export function RoomScroller() {
  const { data } = useCamereQuery();
  const trad = useTraduzioniQuery();
  return (
    <ScrollContainer>
      {data?.cameraCollection?.items.map((camera) => (
        <RoomCard key={camera?.titolo}>
          <img src={camera?.foto?.url + "?fm=webp&h=700&w=700" || ""} />
          <CameraName>{camera?.titolo}</CameraName>
          <p>
            {camera?.caratteristiche?.[0]} <br />
            {camera?.caratteristiche?.[1]}
          </p>
          <p>
            {camera?.caratteristiche?.[2]} <br />
            {camera?.caratteristiche?.[3]}
          </p>

          <RoomLink href={"/hotelroom?roomId=" + camera?.sys.id}>
            <Button>{trad.data?.traduzioniVarie?.scopriDiPi}</Button>
          </RoomLink>
          <RoomLink href="https://reservations.verticalbooking.com//premium/index.html?id_stile=20865&lingua_int=ita&id_albergo=27613&dc=8387">
            <ButtonInverted>
              {trad.data?.traduzioniVarie?.prenota}
            </ButtonInverted>
          </RoomLink>
        </RoomCard>
      ))}
    </ScrollContainer>
  );
}
