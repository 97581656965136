import styled from "styled-components";
import { GoldButton } from "../../components/GoldButton";
import { useCameraQuery, useTraduzioniQuery } from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Scroller } from "../../components/Scroller";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  h2 {
    color: var(--gold);
  }
  p {
    max-width: 80ch;
  }
  i {
    padding-right: 1rem;
  }
`;
const BookRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  i {
    color: var(--gold);
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export function RoomDesc(props: { id: string }) {
  const { data } = useCameraQuery({ variables: { id: props.id || "" } });
  const trad = useTraduzioniQuery();
  return (
    <FlexContainer>
      <div>
        <h2>{data?.camera?.titolo}</h2>
        <p>{data?.camera?.sottitolo}</p>
        {documentToReactComponents(data?.camera?.descrizione?.json)}
        <BookRow>
          <i>{data?.camera?.coloriDisponibili}</i>
          <a href="https://reservations.verticalbooking.com//premium/index.html?id_stile=20865&lingua_int=ita&id_albergo=27613&dc=8387">
            <GoldButton>{trad.data?.traduzioniVarie?.prenota || ""}</GoldButton>
          </a>
        </BookRow>
      </div>
    </FlexContainer>
  );
}
