import { ContactUsStandard } from "../../components/ContactUsStandard";
import { Footer } from "../../components/Footer";
import { IconsRow } from "../../components/IconsRow";
import { SlideScritta, VideoSlide } from "../../components/MainSlide";
import { Partner } from "../../components/Partner";
import { ApiScroller, Scroller } from "../../components/Scroller";
import { TopDescription } from "../../components/TopDescription";
import { WorkWithUs } from "../../components/WorkWithUs";
import { Restaurants } from "../hotel/restaurants";
import { PoolServicesRow } from "../hotel/servicesRow";
import { ServicesResort } from "../resort/Resort";
import { BlackBanner } from "./blackBanner";
import { PoolEvents } from "./events";

export function Pool() {
  return (
    <>
      <VideoSlide
        logo="poolImg/scritta.webp"
        videoUrl="https://tiglivideo.b-cdn.net/piscina.mp4"
      />
      <TopDescription reverse id="2cp966P02pvD8lE7JzhyPS" />
      <PoolServicesRow />
      <ApiScroller id="cZJ2w7vpjxO8T75c2Nwm4" />

      <BlackBanner />
      <PoolEvents />
      <IconsRow />
      <TopDescription reverse id="5wVYjvHZacQbfJEqgqlh3y" />
      <ApiScroller id="4QAZk5WJobZTbTGZL1JHmi" />

      <Restaurants />
      <ServicesResort />
      <ContactUsStandard />
      <Partner />
      <WorkWithUs />
      <Footer
        number="+390318180800"
        email="info@thepadelresort.it"
        prettyNumber="+39 031 8180 800"
      />
    </>
  );
}
