import styled from "styled-components";
import { GoldButton } from "../../components/GoldButton";
import { usePoolQuery, useTraduzioniQuery } from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Container = styled.div`
  padding: 2rem;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    max-width: 70ch;
    text-align: center;
  }
  color: white;
`;

const ButtonContainer = styled.div`
  max-width: 70ch;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  margin-top: 2rem;
  justify-content: space-evenly;
`;

export function BlackBanner() {
  const { data } = usePoolQuery();
  const trad = useTraduzioniQuery();
  return (
    <Container>
      {documentToReactComponents(data?.pool?.testoSfondoNero?.json)}
      <ButtonContainer>
        <a href={data?.pool?.prezzi?.url || ""}>
          <GoldButton inverted>{trad.data?.traduzioniVarie?.prezzi}</GoldButton>
        </a>
        <a href="https://thepadelresort.prenota-web.it/prenotazioni/?iframe=1&lang=it&ristorante=piscina">
          <GoldButton>{trad.data?.traduzioniVarie?.prenota}</GoldButton>
        </a>
      </ButtonContainer>
    </Container>
  );
}
