import styled from "styled-components";
import { PoolServicesRow } from "./servicesRow";
import { GoldButton } from "../../components/GoldButton";
import {
  useMainDescriptionsQuery,
  useTraduzioniQuery,
} from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  h1 {
    color: var(--gold);
    text-align: center;
    text-transform: uppercase;
  }
  & > img {
    object-fit: cover;
    width: 100%;
    max-height: 60vh;
    margin-bottom: -4px;
  }

  & > p {
    max-width: 70ch;

    align-self: center;
    padding: 2rem;
  }
`;

const ButtonsContainer = styled.div`
  align-self: center;
  width: 100%;
  max-width: 70ch;

  display: flex;
  justify-content: space-evenly;
`;

export function Pool() {
  const { data } = useMainDescriptionsQuery({
    variables: { id: "2cp966P02pvD8lE7JzhyPS" },
  });
  const varTrad = useTraduzioniQuery();
  return (
    <Container>
      <h1>{varTrad.data?.traduzioniVarie?.piscina}</h1>
      <img src="/hotelImg/pool.webp" />
      <PoolServicesRow />
      {documentToReactComponents(
        data?.descrizionePrincipale?.descrizione?.json
      )}
      <ButtonsContainer>
        <a href="/pool">
          <GoldButton>{varTrad.data?.traduzioniVarie?.scopriDiPi}</GoldButton>
        </a>
        <a href="https://thepadelresort.prenota-web.it/prenotazioni/?iframe=1&lang=it&ristorante=piscina">
          <GoldButton inverted>
            {varTrad.data?.traduzioniVarie?.prenota}
          </GoldButton>
        </a>
      </ButtonsContainer>
    </Container>
  );
}
