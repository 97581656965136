import styled from "styled-components";
import { GoldButton } from "../../components/GoldButton";
import { useRistoranteQuery } from "../../generated/graphql";
const Container = styled.div`
  background-color: black;

  color: white;
  background-color: black;
  padding: 40px 0px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > p {
    max-width: 900px;
    font-weight: 200;
    font-size: 1.3rem;
    padding: 20px;
  }
  & > h1 {
    font-weight: 200;
    font-size: 2rem;
    margin: 0;
    color: #a48559;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  & > div {
    flex: 1;
    max-width: 400px;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    gap: 50px;
    & > div {
      max-width: 500px;
    }
  }
`;
const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
  font-size: 2em;
  gap: 20px;
  align-items: center;
  & > img {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;

    object-fit: cover;
  }

  & ${GoldButton} {
    max-width: 300px;
    &:hover {
      background-color: white;
      color: #a48559;
    }
  }
`;

const BookButton = styled(GoldButton)`
  background-color: white;
  color: black;
  width: 100%;
  max-width: 390px;

  &:hover {
    background-color: #a48559;
    color: black;
  }
`;

const BookButtonContainer = styled.div`
  display: flex;

  gap: 50px;
  margin-top: 70px;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
function ImgBlock(props: { img: string; title: string; url?: string }) {
  return (
    <ImgContainer>
      <img src={props.img} />
      <div>{props.title}</div>

      <a target="_blank" rel="noreferrer" href={props.url}>
        <GoldButton>Menu</GoldButton>
      </a>
    </ImgContainer>
  );
}

export function Filosofia(props: {
  setPrentota: (value: boolean | "ristorante" | "bistrot") => void;
}) {
  const { data } = useRistoranteQuery({});

  return (
    <Container>
      <h1>{data?.ristoranteFilosofia?.titolo}</h1>
      <p>{data?.ristoranteFilosofia?.testoFilosofia}</p>
      <FlexContainer>
        <ImgBlock
          title="Ristorante"
          img="filosofia/ristorante.webp"
          url={data?.ristoranteFilosofia?.menuRis?.url || ""}
        />
        <ImgBlock
          img="filosofia/bistrot.webp"
          title="Bistrot"
          url={data?.ristoranteFilosofia?.menuBistrot?.url || ""}
        />
        <ImgBlock
          title="LOUNGE BAR"
          img="filosofia/lounge.webp"
          url={data?.ristoranteFilosofia?.menuLoungeBar?.url || ""}
        />
      </FlexContainer>
      <BookButtonContainer id="book">
        <BookButton onClick={() => props.setPrentota("ristorante")}>
          {data?.ristoranteFilosofia?.prenotaRistorante}
        </BookButton>
        <BookButton onClick={() => props.setPrentota("bistrot")}>
          {data?.ristoranteFilosofia?.prenotaBistrot}
        </BookButton>
      </BookButtonContainer>
    </Container>
  );
}
