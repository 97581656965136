import styled from "styled-components";
import { GoldButton } from "../../components/GoldButton";
import {
  useRistoranteQuery,
  useTraduzioniQuery,
} from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useEffect, useState } from "react";
import { adatta } from "../restaurant/restaurant";

const Container = styled.div`
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  h1 {
    color: var(--gold);
    text-align: center;
    text-transform: uppercase;
  }
  & > p {
    max-width: 70ch;
    padding: 0 1em;
    text-align: center;
  }
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const RestContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  display: grid;
  grid-template-columns: repeat(3, minmax(20ch, 50ch));
  grid-template-rows: auto auto auto auto;
  justify-content: space-evenly;

  @media screen and (max-width: 1000px) {
    grid-template-columns: minmax(20ch, 50ch);
  }
`;
const FrameContainer = styled.div`
  width: 100%;
`;
const RestCardContainer = styled.div`
  padding: 1em;
  display: grid;
  grid-template-columns: 1fr;
  grid-row: span 4;
  grid-template-rows: subgrid;
  & > img {
    height: 100%;
    width: 100%;
    max-height: 60vh;
    object-fit: cover;
    object-position: bottom;
  }
  h2 {
    color: var(--gold);
    margin-bottom: 0;
  }
  p {
    text-align: justify;
  }
  & > div {
    display: flex;
    justify-content: space-around;
  }
  @media screen and (max-width: 1000px) {
    margin-bottom: 2em;
  }
`;

function RestCard(props: {
  img: string;
  text?: string | React.ReactNode;
  book?: () => void;
  title?: string;
  menu?: string;
}) {
  const TradVarie = useTraduzioniQuery();
  return (
    <RestCardContainer>
      <img src={props.img}></img>
      <h2>{props.title}</h2>
      <p>{props.text}</p>
      <div>
        <a href={props.menu}>
          <GoldButton>Menù</GoldButton>
        </a>
        {props.book && (
          <GoldButton inverted onClick={props.book}>
            {TradVarie.data?.traduzioniVarie?.prenota}
          </GoldButton>
        )}
      </div>
    </RestCardContainer>
  );
}

export function Restaurants() {
  const { data } = useRistoranteQuery({});
  const [isPrenota, setPrenota] = useState<"ristorante" | "bistrot" | boolean>(
    false
  );
  const TradVarie = useTraduzioniQuery();
  useEffect(() => {
    if (window.addEventListener) {
      // For standards-compliant web browsers
      window.addEventListener("message", adatta, false);
    }
  });
  const lang = localStorage.getItem("lang") || "it";

  return (
    <Container>
      <h1>{TradVarie.data?.traduzioniVarie?.ristorante}</h1>
      <p>
        {documentToReactComponents(
          data?.ristoranteFilosofia?.descrizioneBreve?.json
        )}
      </p>
      <RestContainer>
        <RestCard
          book={() => setPrenota("ristorante")}
          menu={data?.ristoranteFilosofia?.menuRis?.url || ""}
          img="/filosofia/ristorante.webp"
          title="RISTORANTE"
          text={documentToReactComponents(
            data?.ristoranteFilosofia?.descrizioneRistorante?.json
          )}
        />
        <RestCard
          title="BISTROT"
          book={() => setPrenota("bistrot")}
          menu={data?.ristoranteFilosofia?.menuBistrot?.url || ""}
          img="/filosofia/bistrot.webp"
          text={documentToReactComponents(
            data?.ristoranteFilosofia?.descrizioneBistrot?.json
          )}
        />
        <RestCard
          title="LOUNGE BAR"
          menu={data?.ristoranteFilosofia?.menuLoungeBar?.url || ""}
          img="/filosofia/lounge.webp"
          text={documentToReactComponents(
            data?.ristoranteFilosofia?.descrizioneLoungeBar?.json
          )}
        />
      </RestContainer>
      <FrameContainer>
        {isPrenota && (
          <iframe
            title="Prenota"
            src={
              `https://thepadelresort.prenota-web.it/prenotazioni/?iframe=1&lang=${lang}` +
              (isPrenota === "ristorante" ? "" : "&ristorante=bistrot")
            }
            allowFullScreen
            style={{ border: "none", width: "100%", height: "1000px" }}
            id="iFramePrenota"
          ></iframe>
        )}
      </FrameContainer>
    </Container>
  );
}
