import styled from "styled-components";
import { usePadelQuery } from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
const Container = styled.div`
  background-color: black;
  color: white;
  flex-direction: row;

  display: flex;
  align-items: center;

  padding: 50px;

  gap: 50px;

  & > h1 {
    font-size: 2em;
  }
  img {
    max-width: 500px;
  }
  & > div {
    align-self: flex-start;
    min-width: 300px;
    flex: 1;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export function PadelAcademy() {
  const { data } = usePadelQuery();
  return (
    <Container>
      <img src="padel_academy.webp" />
      <div>
        <h1>{data?.padelAccademyDesc?.titolo}</h1>
        <div>
          {documentToReactComponents(
            data?.padelAccademyDesc?.descrizione?.json
          )}
        </div>
      </div>
    </Container>
  );
}
