import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/v2h0bz1hqac3/",
  cache: new InMemoryCache(),
  headers: {
    Authorization: "Bearer 5g1wtObit27A1jFhpl8g6gm821Dq8ZjGOD-iHgvtBkg",
  },
  defaultOptions: {
    watchQuery: {
      variables: { locale: localStorage.getItem("lang") || "it" },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
