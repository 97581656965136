import styled from "styled-components";
import { GoldButton } from "../../components/GoldButton";
import {
  useExpirienceQuery,
  useTraduzioniQuery,
} from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { CreateCheckout } from "./checkout";
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px auto 0px;
  max-width: 1200px;
  grid-column-gap: 2rem;
  padding: 0 1rem;

  @media screen and (max-width: 800px) {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    padding: 0;
    max-width: 600px;
  }
`;
const Introduction = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  color: var(--gold);
  height: min-content;

  height: min-content;
`;
const Description = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  @media screen and (max-width: 800px) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
`;
const Img = styled.img`
  align-self: center;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  width: 100%;
  max-height: 40vh;
  object-fit: contain;
  @media screen and (max-width: 800px) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
`;

const FlexContainer = styled.div`
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  background-color: var(--grey);
`;
const Price = styled.div`
  color: var(--gold);
  margin-top: 1rem;
`;

const Button = styled(GoldButton)`
  margin-block-end: 0;
  height: fit-content;
  padding-bottom: 0.5rem;

  text-align: center;
  border-bottom: 1px solid var(--gold);
  padding: 0.5rem;
  width: 100px;
  align-self: center;
  justify-self: center;
  font-size: 1rem;
  margin-top: 1rem;
`;

const DescOverflow = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 40vh;
`;
export function Expiriences(props: {
  title?: React.ReactNode;
  img?: string;
  id: string;
  productId: string;
}) {
  const { data } = useExpirienceQuery({
    variables: { id: props.id },
  });
  const TradVarie = useTraduzioniQuery();
  return (
    <FlexContainer>
      <Container>
        <Introduction>
          <p>
            <b>{data?.expirience?.titolo}</b>
          </p>
          <p>{data?.expirience?.sottotitolo}</p>
        </Introduction>
        <Description>
          <DescOverflow>
            {documentToReactComponents(data?.expirience?.descrizione?.json) ||
              ""}
          </DescOverflow>
          <Price>
            <b>€{data?.expirience?.prezzo}</b>
            <div>
              <Button onClick={() => CreateCheckout(props.productId)}>
                {TradVarie.data?.traduzioniVarie?.acquista}
              </Button>
            </div>
          </Price>
        </Description>
        <Img src={props.img} />
        {props.title}
      </Container>
    </FlexContainer>
  );
}
