import styled from "styled-components";
import { GoldButton } from "../../components/GoldButton";
import { useServicePageQuery } from "../../generated/graphql";

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: min(30vw, 400px) auto;
  grid-template-rows: min(30vw, 400px) 30px;
  width: 100%;
  flex: 1;

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 100vw auto;
    justify-items: center;
  }
`;

const ImgBlock = styled.div<{ $url: string }>`
  grid-column: 1;
  background-image: ${(props) => `url(${props.$url})`};

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 950px) {
    grid-column: 1;
    grid-row: 2;
    width: 100vw;
  }
`;
const ButtonsBlock = styled.div`
  grid-column: 2;
  padding-left: 10px;
  margin-top: -10px;
  & button {
    height: 3rem;
    padding: 5px 20px;
    margin: 10px;
    font-weight: 300;
    font-size: 1rem;
  }
  @media screen and (max-width: 950px) {
    grid-column: 1;
    grid-row: 3;
    padding: 0;
    margin-top: 10px;
  }
`;
const InfoButton = styled(GoldButton)`
  background-color: white;
  color: black;
  border: 1px solid #a48559;
  &:hover {
    color: white;
  }
`;
const DescBlock = styled.div`
  grid-column: 2;
  border: 3px solid #a48559;
  border-left: none;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  max-width: 800px;
  & h2 {
    display: inline;
    color: #a48559;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
  }
  & h3 {
    margin-left: 10px;
    display: inline;
    color: #747272;
    font-style: italic;
    font-weight: 300;
    font-size: 1.2rem;
  }
  @media screen and (max-width: 950px) {
    grid-column: 1;
    grid-row: 1;
    border: 3px solid #a48559;
    border-bottom: none;
    margin: 0 10px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 100px;
  flex-direction: column;
  & > div {
    padding-bottom: 40px;
  }
  & > div:nth-child(even) {
    background-color: #f8f7f7;
  }

  @media screen and (min-width: 1460px) {
    & > div:nth-child(even) {
      padding-right: calc((100vw - 1300px));
    }
    & > div:nth-child(odd) {
      padding-left: calc((100vw - 1300px));
    }
  }
  @media screen and (min-width: 950px) {
    & > div:nth-child(even) {
      grid-template-columns: auto min(30vw, 400px);

      justify-content: end;
      & ${DescBlock} {
        grid-column: 1;
        grid-row: 1;
        border: 3px solid #a48559;

        border-right: none;
      }
      & ${ImgBlock} {
        grid-column: 2;
        grid-row: 1;
      }
      & ${ButtonsBlock} {
        grid-column: 1;
      }
    }
  }
`;

function ServiceDescBlock(props: { id: string; url: string }) {
  const { data } = useServicePageQuery({
    variables: { id: props.id },
  });

  return (
    <ContainerGrid>
      <ImgBlock $url={props.url} />
      <DescBlock>
        <h2>{data?.bloccoServizi?.titolo}</h2>
        <h3>{data?.bloccoServizi?.sottoTitolo}</h3>

        <p>{data?.bloccoServizi?.testo}</p>
      </DescBlock>
      <ButtonsBlock>
        <a
          target="_blank"
          rel="noreferrer"
          href={
            data?.bloccoServizi?.listino?.url ||
            data?.bloccoServizi?.linkPrenota ||
            ""
          }
        >
          <GoldButton>{data?.bloccoServizi?.testoPulsantePrimario}</GoldButton>
        </a>

        <a
          target="_blank"
          rel="noreferrer"
          href={data?.bloccoServizi?.linkInfo || ""}
        >
          <InfoButton>{data?.bloccoServizi?.testoPulsanteInfo}</InfoButton>
        </a>
      </ButtonsBlock>
    </ContainerGrid>
  );
}
export function ServiceDescList() {
  return (
    <Container>
      <ServiceDescBlock
        id="6Wre6TvLFmmDUrNR1UYfQD"
        url="imgServices/pale.webp"
      />
      <ServiceDescBlock
        id="5xsVXXAbr7QBKriD2KGJmM"
        url="imgServices/work.webp"
      />
      <ServiceDescBlock
        id="1cBpyl04phUZpRlIxOIPiy"
        url="imgServices/fisio.webp"
      />
      <ServiceDescBlock
        id="10pgVv1RRHhXZNOWR8qe1P"
        url="imgServices/proshop.webp"
      />
    </Container>
  );
}
