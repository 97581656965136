import { Footer } from "../../components/Footer";
import { MainSlide, SlideScritta } from "../../components/MainSlide";
import { ArticlesGrid } from "./articlesGrid";

export function Press() {
  return (
    <>
      <SlideScritta signUrl="/scrittavideo.webp" bgUrl="/topPics/press.webp" />

      <ArticlesGrid />
      <Footer
        number="+390318180860"
        email="info@thepadelresort.it"
        prettyNumber="+39 031 8180 860"
      />
    </>
  );
}
