import styled from "styled-components";
import { useContattiQuery } from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ContactForm } from "./ContactForm";
const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const ContactDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  padding: 20px;
  max-width: 350px;
  @media screen and (max-width: 900px) {
    padding: 0px;
  }
`;

const PadelLogo = styled.div`
  width: 100%;
  aspect-ratio: 2/1;
  max-width: 300px;
  background-repeat: no-repeat;
  background-image: url("logowhitebg.webp");
  background-size: contain;
`;
function ContactDetails() {
  const { data } = useContattiQuery();
  return (
    <ContactDetailsContainer>
      <PadelLogo></PadelLogo>
      <p>{documentToReactComponents(data?.contatti?.datiAzienda?.json)}</p>
    </ContactDetailsContainer>
  );
}

export function ContactFormFlex() {
  return (
    <FlexContainer>
      <ContactDetails />
      <ContactForm />
    </FlexContainer>
  );
}
