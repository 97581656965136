import styled from "styled-components";
import { GoldButton } from "./GoldButton";
import { animated, useInView } from "@react-spring/web";
import { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useContattiQuery } from "../generated/graphql";

const LogoSend = styled(animated.div)`
  grid-column: 1 / 3;
  background-image: url(/icon_send.png);
  height: 100px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, auto);
  grid-column-gap: 2rem;
  grid-row-gap: 1.4rem;
  justify-items: center;
  margin-top: 2rem;
  padding: 2rem;
  &.black {
    background-color: black;
    color: white;

    & ${LogoSend} {
      background-image: url(/iconContactUs.webp);
    }

    & input {
      background-color: black;
      color: white;
      border-color: white;
      &::placeholder {
        color: white;
      }
    }
    & button:hover {
      color: black;
      background-color: white;
    }
  }
`;

const TextSubscribe = styled.div`
  grid-column: 1 / 3;
  font-size: 1.6rem;
  font-weight: 300;

  text-align: center;
  max-width: 800px;
`;

export const CustomInput = styled.input`
  height: 4rem;
  width: 100%;
  border: 1px solid #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-indent: 1rem;
  max-width: 400px;
  &:focus {
    outline-color: #a48559;
  }
  &::placeholder {
    color: #000;
    font-family: Montserrat;

    font-size: 1rem;
    font-style: normal;
    font-weight: 100;
  }
`;
const NameField = styled(CustomInput)`
  grid-column: 1 / 2;
  justify-self: end;
  @media screen and (max-width: 900px) {
    grid-column: 1 / 3;
    justify-self: auto;
    max-width: 838px;
  }
`;
const SurnameField = styled(CustomInput)`
  grid-column: 2 / 3;
  justify-self: start;
  @media screen and (max-width: 900px) {
    grid-column: 1 / 3;
    justify-self: auto;
    max-width: 838px;
  }
`;
const EmailField = styled(CustomInput)`
  grid-column: 1 / 3;
  max-width: 838px;
`;

const CheckBoxContainer = styled.div`
  grid-column: 1 / 3;
  width: 100%;
  max-width: 850px;
  font-size: 1.3rem;
  display: flex;
  font-weight: 250;
  align-items: center;
  gap: 10px;
  & > input {
    width: 1.3rem;
    height: 1.3rem;
  }
  @media screen and (max-width: 900px) {
    font-size: 1rem;
  }
`;

const SubmitButtonContainer = styled.div`
  grid-column: 1 / 3;
  width: 100%;
  max-width: 850px;
`;

export function ContactUs(props: {
  text: string;
  url: string;
  blackTheme?: boolean;
}) {
  const [ref, springs] = useInView(() => ({
    from: {
      transform: "rotate(90deg)",
    },
    to: {
      transform: "rotate(0deg)",
    },
  }));
  const [formData, setFormData] = useState({
    FNAME: "",
    LNAME: "",
    EMAIL: "",
    tags: props.blackTheme ? "11241836" : "",
  });
  const { data } = useContattiQuery();

  return (
    <Container className={props.blackTheme ? "black" : ""}>
      <LogoSend style={springs} />
      <TextSubscribe ref={ref}>{props.text}</TextSubscribe>
      <MailchimpSubscribe
        url={props.url}
        render={({ subscribe, status, message }) => (
          <>
            <NameField
              placeholder="Nome"
              onChange={(val) =>
                setFormData({ ...formData, FNAME: val.target.value })
              }
              value={formData.FNAME}
            />
            <SurnameField
              placeholder="Cognome"
              onChange={(val) =>
                setFormData({ ...formData, LNAME: val.target.value })
              }
              value={formData.LNAME}
            />
            <EmailField
              placeholder="Email"
              type="email"
              onChange={(val) =>
                setFormData({ ...formData, EMAIL: val.target.value })
              }
              value={formData.EMAIL}
            />
            <CheckBoxContainer>
              <input
                type="checkbox"
                id={props.blackTheme ? "vehicle1" : "vehicle"}
                name="vehicle1"
                value="Bike"
              />
              <label htmlFor={props.blackTheme ? "vehicle1" : "vehicle"}>
                {data?.contatti?.testoAccettoPrivacy || ""}
                <a href="https://www.iubenda.com/privacy-policy/21747366">
                  Privacy policy
                </a>
              </label>
            </CheckBoxContainer>
            <SubmitButtonContainer>
              {status !== "success" ? (
                <GoldButton
                  onClick={() => subscribe(formData)}
                  disabled={
                    formData.EMAIL === "" ||
                    formData.FNAME === "" ||
                    formData.LNAME === ""
                  }
                >
                  {status === "sending" ? "Sto Inviando" : "Invia"}
                </GoldButton>
              ) : (
                <>{message}</>
              )}
            </SubmitButtonContainer>
          </>
        )}
      />
    </Container>
  );
}
