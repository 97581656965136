import styled from "styled-components";
import { Scroller } from "../../components/Scroller";
import { useComoShowCaseQuery, useImgDescQuery } from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  background-color: var(--grey);
  h1 {
    color: var(--gold);
    text-align: center;
  }

  & > p {
    max-width: 70ch;

    align-self: center;
    text-align: center;
  }
`;

export function Como(props: { id: string }) {
  const { data } = useComoShowCaseQuery({ variables: { id: props.id } });
  return (
    <Container>
      <h1>{data?.comoShowCase?.title}</h1>
      {documentToReactComponents(data?.comoShowCase?.testo?.json) || ""}
      <ScrollerContainer>
        <ComoScroller />
      </ScrollerContainer>
    </Container>
  );
}

const CityCard = styled.div`
  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
  }
  background-color: #a48559;
  color: white;

  box-sizing: border-box;
  h2 {
    text-align: center;
  }
  p {
    padding: 0 1em;
  }
`;
const ScrollerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1em;
`;

function ComoScroller() {
  const { data } = useImgDescQuery();
  return data?.imgTitleDescCollection?.items.map((item) => (
    <CityCard key={item?.title}>
      <img src={item?.img?.url || ""} />
      <h2>{item?.title}</h2>
      <p>{item?.description}</p>
    </CityCard>
  ));
}
