import styled, { css } from "styled-components";

export const GoldButton = styled.button<{ inverted?: boolean }>`
  background-color: #a48559;
  outline: 1px solid var(--gold);
  color: white;
  border: none;
  height: 4rem;
  min-width: 9rem;
  font-size: 1.3rem;

  text-transform: uppercase;
  cursor: pointer;

  font-family: Montserrat;
  font-weight: inherit;
  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    opacity: 0.5;
  }

  & > a {
    font-size: 1.2rem;
    font-weight: 200;
    color: white;
    text-decoration: none;
  }
  &:hover {
    background-color: black;
  }
  ${(props) =>
    props.inverted &&
    css`
      background-color: white;
      color: var(--gold);
      outline: 1px solid var(--gold);
    `}
`;
