import styled from "styled-components";
import { useInView, animated } from "@react-spring/web";
import { useIconeQuery } from "../generated/graphql";

const Container = styled.div`
  display: flex;

  justify-content: space-evenly;
  background-color: #a48559;
  padding: 20px;
  color: white;
  text-align: center;
  gap: 10px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const IconContainter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Icon = styled(animated.div)<{ $url: string }>`
  height: 55px;
  width: 100px;
  margin-bottom: 15px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.$url})`};
`;

function AnimatedIcon(props: { $url: string; text: string }) {
  const [ref, springs] = useInView(() => ({
    from: {
      opacity: 0,
      y: 100,
    },
    to: {
      opacity: 1,
      y: 0,
    },
  }));
  const [refText, springsText] = useInView(() => ({
    from: {
      opacity: 0,
      y: -100,
    },
    to: {
      opacity: 1,
      y: 0,
    },
  }));
  return (
    <IconContainter>
      <Icon $url={props.$url} ref={ref} style={springs} />
      <animated.div ref={refText} style={springsText}>
        {props.text}
      </animated.div>
    </IconContainter>
  );
}
export function IconsRow() {
  const { data } = useIconeQuery();
  return (
    <Container>
      <AnimatedIcon
        $url="icons/road.webp"
        text={data?.icone?.autostrada || ""}
      />
      <AnimatedIcon $url="icons/como.webp" text={data?.icone?.como || ""} />
      <AnimatedIcon $url="icons/lugano.webp" text={data?.icone?.lugano || ""} />
      <AnimatedIcon $url="icons/duomo.webp" text={data?.icone?.milano || ""} />
      <AnimatedIcon
        $url="icons/park.webp"
        text={data?.icone?.parcheggi || ""}
      />
    </Container>
  );
}
