import styled from "styled-components";

export const RotateImg = styled.div<{ $url: string }>`
  position: absolute;

  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  &::after {
    content: "";
    opacity: 0;
    background-repeat: no-repeat;
    position: absolute;
    top: 100%;
    left: 50%;
    height: 200%;
    width: 100%;
    background-image: ${(props) => `url(${props.$url})`};
    background-position: bottom;
    background-size: 100%, 100%;

    transition: transform var(--trans-duration) var(--timing-function),
      opacity var(--trans-duration) ease-out;
  }
  &.active::after {
    opacity: 1;
  }
`;
