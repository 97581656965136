import styled from "styled-components";
import { useTraduzioniQuery } from "../generated/graphql";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 0px;
  grid-row-gap: 4rem;
  background-color: black;
  padding: 4rem;
  color: white;
  word-wrap: break-word;
  white-space: pre-line;
  @media screen and (max-width: 900px) {
    padding: 2rem;
    justify-items: center;
    grid-row-gap: 3rem;
  }
  @media screen and (min-width: 1600px) {
    padding: 10rem;
    padding-top: 4rem;
  }
`;

const Logo = styled.div`
  grid-column: 2 / 3;
  background-image: url(/logoCentered.webp);
  background-repeat: no-repeat;
  background-size: 200px;
  height: 154px;
  width: 200px;
  justify-self: center;
  @media screen and (max-width: 900px) {
    grid-column: 1 / 4;
  }
`;

const InfoWithIcon = styled.div<{ $url: string }>`
  position: relative;
  padding-left: 2.5rem;
  font-size: 1.2rem;
  font-weight: 200;
  & > a {
    font-size: 1.2rem;
    font-weight: 200;
    color: white;
    text-decoration: none;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 40px;
    background-repeat: no-repeat;
    background-image: ${(props) => `url(${props.$url})`};
    background-size: 27px;
  }
`;

const LocationInfo = styled(InfoWithIcon)`
  grid-column: 1 / 2;
  &::first-line {
    font-weight: 600;
  }
  @media screen and (max-width: 900px) {
    grid-column: 1 / 4;
  }
`;

const ContactInfoContainer = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-self: center;
  @media screen and (max-width: 900px) {
    grid-column: 1 / 4;
  }
`;
const ContainerFollowUs = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(2, auto);
  justify-content: center;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;

  justify-self: end;
  & > :first-child {
    grid-column: 1 / 5;
    text-align: center;
    font-weight: 600;
  }
  @media screen and (max-width: 900px) {
    grid-column: 1 / 4;
    justify-self: center;
  }
`;

const SocialIcon = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;

  height: 30px;
  width: 30px;
`;

const FbIcon = styled(SocialIcon)`
  background-image: url(/fb.webp);
  grid-column: 1 / 2;
  justify-self: end;
`;
const InstaIcon = styled(SocialIcon)`
  background-image: url(/insta.webp);
  grid-column: 2 / 3;
  justify-self: start;
`;
const LinkeIcon = styled(SocialIcon)`
  background-image: url(/linkedin.webp);
  grid-column: 3 / 4;
  justify-self: start;
`;
const TikTokIcon = styled(SocialIcon)`
  background-image: url(/tiktok.webp);
  grid-column: 4 / 5;
  justify-self: start;
`;

const FooterText = styled.div`
  grid-column: 1 / 4;
  justify-self: center;
  font-size: 0.8rem;
  text-align: center;
`;

export function Footer(props: {
  number: string;
  email: string;
  prettyNumber: string;
}) {
  const { data } = useTraduzioniQuery();

  return (
    <Container>
      <Logo />

      <LocationInfo $url="locator.png">
        <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47869dceecce2f39:0x11e4eb70136d0bf0?sa=X&ved=1t:8290&hl=it-IT&ictx=111">
          {`THE PADEL RESORT \n Via Pasquale Paoli 55 \n22100, Como - Italia`}{" "}
        </a>
      </LocationInfo>

      <ContactInfoContainer>
        <InfoWithIcon $url="mail.png">
          <a href={`mailto:${props.email}`}>{props.email}</a>
        </InfoWithIcon>
        <InfoWithIcon $url="tel.png">
          <a href={`tel:${props.number}`}>{props.prettyNumber}</a>
        </InfoWithIcon>
      </ContactInfoContainer>
      <ContainerFollowUs>
        <div>{data?.traduzioniVarie?.seguici}</div>
        <a href="https://www.facebook.com/profile.php?id=61550622727019">
          <FbIcon />
        </a>
        <a href="https://www.instagram.com/thepadelresort/">
          <InstaIcon />
        </a>
        <a href="https://www.linkedin.com/company/the-padel-resort/">
          <LinkeIcon />
        </a>
        <a href="https://www.tiktok.com/@the_padel_resort">
          <TikTokIcon />
        </a>
      </ContainerFollowUs>

      <FooterText>
        Ibiscus S.r.l. © {new Date().getFullYear()} - P. Iva: 03905570135 -
        Powered by NTHN Studios
      </FooterText>
    </Container>
  );
}

const ReducedFooterIcons = styled.div`
  display: flex;

  justify-content: center;
  gap: 20px;
  padding: 20px;
`;

const ReduceFooterContainer = styled.div`
  background-color: black;
  padding: 20px;
  color: white;
`;
export function ReducedFooter() {
  return (
    <ReduceFooterContainer>
      <ReducedFooterIcons>
        <a href="https://www.facebook.com/profile.php?id=61550622727019">
          <FbIcon />
        </a>
        <a href="https://www.instagram.com/thepadelresort/">
          <InstaIcon />
        </a>
        <a href="https://www.linkedin.com/company/the-padel-resort/">
          <LinkeIcon />
        </a>
        <a href="https://www.tiktok.com/@the_padel_resort">
          <TikTokIcon />
        </a>
      </ReducedFooterIcons>

      <FooterText>
        Ibiscus S.r.l. © {new Date().getFullYear()} - P. Iva: 03905570135 –
        Powered by NTHN Studios
      </FooterText>
    </ReduceFooterContainer>
  );
}
