import styled from "styled-components";
import { GoldTitle } from "./GoldTitle";
import { animated, useInView } from "@react-spring/web";
import { useTraduzioniQuery } from "../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
const ParnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 300px;
  gap: 1rem;
  margin: 1rem;

  & > img {
    max-height: 130px;
    max-width: 200px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;

    margin: 5rem;
    gap: 2rem;
    margin-bottom: 140px;
    flex: 1;
    height: 100%;
  }
`;
const Container = styled.div`
  margin-top: 50px;
  padding: 50px;
  background-color: #f8f7f7;
  text-align: center;
  font-size: 1.3em;
`;

function ImgBrand(props: { src: string }) {
  const [ref, springs] = useInView(() => ({
    from: {
      opacity: 0,
      y: 100,
    },
    to: {
      opacity: 1,
      y: 0,
    },
  }));

  return <animated.img src={props.src} alt="brand" ref={ref} style={springs} />;
}
export function Partner(props: { padel?: boolean }) {
  const { data } = useTraduzioniQuery();
  return (
    <Container>
      <GoldTitle>{data?.traduzioniVarie?.nostriPartner}</GoldTitle>
      <ParnerContainer>
        <ImgBrand src="partners/iperauto.webp" />
        <ImgBrand src="partners/maserati.webp" />
        <ImgBrand src="partners/logo-bernardo.webp" />
        <ImgBrand src="partners/longevity.webp" />
        {props.padel && <ImgBrand src="partners/head.webp" />}
      </ParnerContainer>
      {documentToReactComponents(
        data?.traduzioniVarie?.vuoiDiventarePartner?.json
      )}
    </Container>
  );
}
