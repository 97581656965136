import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
const containerStyle = {
  width: "98vw",
  height: "500px",
};

const center = {
  lat: 45.784672718311775,
  lng: 9.072072936218067,
};

export function ContactsMap() {
  return (
    <>
      <APIProvider apiKey={"AIzaSyDW0J2cWhz7IHSachcUqixzFfbdeKu2wfM"}>
        <Map
          defaultCenter={center}
          defaultZoom={12}
          style={containerStyle}
          mapId={"f954b332a52d5539"}
        >
          <Marker position={center} />
        </Map>
      </APIProvider>
    </>
  );
}
