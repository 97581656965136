import styled from "styled-components";
import { useArticlesQuery } from "../../generated/graphql";
export const Grid = styled.div`
  @media screen and (min-width: 800px) {
    --columns-number: 2;
  }
  @media screen and (min-width: 1000px) {
    --columns-number: 3;
  }
  @media screen and (min-width: 1400px) {
    --columns-number: 4;
  }
  @media screen and (min-width: 2200px) {
    --columns-number: 5;
  }
  display: grid;
  grid-template-columns: repeat(var(--columns-number), 1fr);
  grid-template-rows: repeat(var(--columns-number), auto auto auto auto);
  grid-column-gap: 1.4rem;

  margin-top: 2rem;
  padding: 2rem;
`;

export const ArticleContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-row: span 4;
  grid-template-rows: subgrid;

  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  margin-bottom: 2rem;
  & img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
`;

export const Source = styled.span`
  font-weight: 700;
  font-size: 0.8rem;
`;
export const DateField = styled.span`
  font-size: 0.7rem;
`;
export const Separator = styled.span`
  margin: 0 0.5rem;
`;
export const Link = styled.a`
  color: #9f865f;
  text-decoration: underline;
  text-underline-offset: 8px;
`;
export const Heading = styled.div`
  margin-top: 50px;
  text-align: center;
  & h1 {
    font-size: 2.5rem;
    font-weight: 500;
  }
  & h2 {
    font-size: 1.5rem;
    color: #9f865f;
    font-weight: 500;
  }
`;

export const BottomText = styled.p`
  margin: 20px;
  & a {
    color: #9f865f;
    text-decoration: underline;
  }
`;

function parseDate(date: Date) {
  const formattedDate = date.toLocaleDateString("it-IT", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return formattedDate;
}
function Article(props: {
  text?: string;
  img?: string;
  date?: Date;
  source?: string;
  link?: string;
}) {
  return (
    <ArticleContainer>
      <img src={props.img + "?fm=webp"} alt={props.text}></img>
      <div>
        <Source>{props.source}</Source>
        <Separator>-</Separator>
        <DateField>{props.date && parseDate(props.date)}</DateField>
      </div>
      <div>{props.text}</div>

      <Link href={props.link} target="_blank">
        LEGGI L’ARTICOLO
      </Link>
    </ArticleContainer>
  );
}

export function Articles(props: { limit?: number }) {
  const { data, loading, error } = useArticlesQuery({
    variables: { limit: props.limit },
  });
  console.log(loading);
  console.log(error);
  if (!data) return <div></div>;
  console.log(data);
  return (
    <>
      <Grid>
        {data.articoloStampaCollection?.items.map((article) => (
          <Article
            img={article?.immagine?.url || ""}
            text={article?.anteprima || ""}
            source={article?.publisher || ""}
            link={article?.link || ""}
            date={new Date(article?.data)}
            key={article?.link}
          ></Article>
        ))}
      </Grid>
    </>
  );
}

export function ArticlesGrid() {
  return (
    <>
      <Heading>
        <h2>NEWS E MEDIA</h2>
        <h1>Dicono di noi</h1>
      </Heading>

      <Articles limit={100} />
      <BottomText>
        Se siete interessati a saperne di più su di noi, desiderate
        intervistarci o scrivere un articolo riguardante il Resort e le nostre
        attività, vi invitiamo a contattare il nostro ufficio stampa via email
        all'indirizzo{" "}
        <a href="mailto:press@affcomunicazione.it">press@affcomunicazione.it</a>
        .
      </BottomText>
    </>
  );
}
