import styled from "styled-components";
import ReactCurvedText from "react-curved-text";
import { slideNames } from "./types";
import { useRef } from "react";
import { useWindowSize } from "@uidotdev/usehooks";

export const CurvedTextContainer = styled.div`
  top: 100%;
  cursor: pointer;
  user-select: none; /* Standard syntax */

  left: 50%;
  position: absolute;
  text-shadow: 1px 1px black;
  & > svg > text > textPath:hover {
    fill: #bdbdbd;
  }
  & > svg > text > textPath {
    fill: #7b7b7b;
    text-transform: uppercase;
    font-size: 25px;
    @media (max-width: 800px) {
      font-size: 18px;
    }
  }
  &.active > svg > text > textPath {
    fill: #ffffff;
  }
  z-index: 10;
`;
export function CurvedText(props: {
  text: slideNames;
  offset: number;
  onClick: (arg1: slideNames) => void;
  selected: slideNames;
  displayText: string;
}) {
  const { width } = useWindowSize();
  const textRadius = width && width > 800 ? 370 : 165;

  return (
    <CurvedTextContainer
      className={props.selected === props.text ? "active" : ""}
    >
      <ReactCurvedText
        width={100}
        height={100}
        cx={0}
        cy={0}
        rx={textRadius}
        ry={textRadius}
        reversed={true}
        text={props.displayText}
        startOffset={props.offset}
        textProps={{
          onClick: () => props.onClick(props.text),
        }}
        svgProps={{ style: { overflow: "visible", color: "red" } }}
      />
    </CurvedTextContainer>
  );
}
