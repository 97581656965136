import styled from "styled-components";
import { GoldButton } from "../../components/GoldButton";
import { usePadelQuery, useTraduzioniQuery } from "../../generated/graphql";

const Container = styled.div`
  display: flex;

  justify-content: space-evenly;
  text-align: center;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const ImgContainer = styled.div`
  overflow: hidden;

  & > img {
    overflow: hidden;
    display: inline-block;

    width: min(33vw, 700px);
    height: min(calc(33vw / 1.68), 437px);
    margin-bottom: 10px;

    object-fit: cover;
    @media screen and (max-width: 800px) {
      width: min(100vw, 500px);
      height: min(calc(100vw / 1.68), 312px);
      min-width: 300px;
    }
  }
`;

const ImgBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    max-width: 300px;
  }
`;
const Title = styled.div`
  font-size: 1.5em;
  color: #a48559;
`;

const BookContainer = styled.div`
  display: flex;

  justify-content: space-evenly;
  margin-bottom: 4em;
`;

export function BookPadel() {
  const { data } = usePadelQuery();
  const TradVarie = useTraduzioniQuery();

  return (
    <>
      <Container>
        <ImgBlock>
          <ImgContainer>
            <img src="bookPadel/internal.webp" />
          </ImgContainer>
          <Title>{data?.esterni?.titolo}</Title>
          <p>{data?.esterni?.descrizione}</p>
        </ImgBlock>
        <ImgBlock>
          <ImgContainer>
            <img src="bookPadel/external.webp" />
          </ImgContainer>
          <Title>{data?.interni?.titolo}</Title>
          <p>{data?.interni?.descrizione}</p>
        </ImgBlock>
        <ImgBlock>
          <ImgContainer>
            <img src="bookPadel/shop.webp" />
          </ImgContainer>
          <Title> {data?.shop?.titolo}</Title>
          <p> {data?.shop?.descrizione}</p>
        </ImgBlock>
      </Container>
      <BookContainer>
        <a
          href="https://playtomic.io/tenant/0ccaf81a-0f09-4779-8783-e5e4da356f99?q=PADEL~2024-03-20~~~"
          target="_blank"
          rel="noreferrer"
        >
          <GoldButton>{TradVarie.data?.traduzioniVarie?.prenota}</GoldButton>
        </a>
        <a
          href={data?.padelAccademyDesc?.prezzi?.url || ""}
          target="_blank"
          rel="noreferrer"
        >
          <GoldButton inverted>
            {TradVarie.data?.traduzioniVarie?.prezzi}
          </GoldButton>
        </a>
      </BookContainer>
    </>
  );
}
