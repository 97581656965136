import styled from "styled-components";
import { ContactUsStandard } from "../../components/ContactUsStandard";
import { Footer } from "../../components/Footer";
import { IconsRow } from "../../components/IconsRow";
import { SlideScritta } from "../../components/MainSlide";
import { Partner } from "../../components/Partner";
import { WorkWithUs } from "../../components/WorkWithUs";
import { ServicesResort } from "../resort/Resort";
import { Como } from "./como";

import { RoomScroller } from "./RoomsScroller";
import { IncludedInRooms } from "./includedInRooms";
import { CheckinRow, HotelServicesRow } from "./servicesRow";
import { ApiScroller, Scroller } from "../../components/Scroller";
import { RoomDesc } from "./roomdesc";
import { useCameraQuery } from "../../generated/graphql";
const HotelRooms = styled.div`
  background-color: black;
  text-align: center;
  padding: 2rem;
  h1 {
    color: white;
  }
`;

export function HotelRoom() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const roomId = urlParams.get("roomId");
  const { data } = useCameraQuery({ variables: { id: roomId || "" } });

  return (
    <>
      <SlideScritta
        signUrl=""
        bgUrl={data?.camera?.foto?.url + "?fm=webp&w=1000" || ""}
        position="center"
        prenota
        onlick={() =>
          window.open(
            "https://reservations.verticalbooking.com//premium/index.html?id_stile=20865&lingua_int=ita&id_albergo=27613&dc=8387"
          )
        }
      />
      <RoomDesc id={roomId || ""} />
      <ApiScroller id="WkVTA6mKJVibYSCCtQxv4" />

      <CheckinRow text={data?.camera?.checkTimes || ""} />
      <IncludedInRooms />

      <HotelServicesRow />
      <ApiScroller id="3iz7TrgqwFbHZHVj83zHic" />
      <HotelRooms>
        <h1>{data?.hotelPage?.titoloAlternativo}</h1>
        <RoomScroller />
      </HotelRooms>
      <Como id="5sBRPC9FOriTDvWy1SDD55" />
      <IconsRow />
      <ServicesResort />
      <ContactUsStandard />
      <Partner />
      <WorkWithUs />
      <Footer
        number="+390318180800"
        email="info@comohills.it"
        prettyNumber="+39 031 8180 800"
      />
    </>
  );
}
