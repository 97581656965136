import { createStorefrontApiClient } from "@shopify/storefront-api-client";

const checkoutQuery = `
mutation checkoutCreate($id: ID!) {
  checkoutCreate(input: { lineItems: { variantId: $id, quantity: 1 } }) {
    checkout {
      webUrl
      totalPrice {
        amount
      }
    }
  }
}
`;

const client = createStorefrontApiClient({
  storeDomain: "http://padelresort.myshopify.com",
  apiVersion: "2024-04",
  publicAccessToken: "0c8f8c525acd07f5510e1ca684e018a2",
});

export async function CreateCheckout(id: string) {
  const { data, errors, extensions } = await client.request(checkoutQuery, {
    variables: {
      id: "gid://shopify/ProductVariant/" + id,
    },
  });
  window.open(data.checkoutCreate.checkout.webUrl);
}
