import styled from "styled-components";
import { useInView, animated } from "@react-spring/web";
import { usePadelRulesQuery } from "../../generated/graphql";

const Container = styled.div`
  display: flex;

  justify-content: space-evenly;

  padding: 20px;

  text-align: center;
  gap: 10px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const IconContainter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  & p {
    max-width: 200px;
  }
`;
const Icon = styled(animated.div)<{ $url: string }>`
  height: 55px;
  width: 100px;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.$url})`};
`;
const Title = styled.div`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 50px;
`;
const TopTitle = styled.div`
  text-align: center;
  color: #a48559;
  margin-bottom: 5px;
`;
function AnimatedIcon(props: { $url: string; text: string }) {
  const [ref, springs] = useInView(() => ({
    from: {
      opacity: 0,
      y: -100,
    },
    to: {
      opacity: 1,
      y: 0,
    },
  }));
  const [refText, springsText] = useInView(() => ({
    from: {
      opacity: 0,
      y: +100,
    },
    to: {
      opacity: 1,
      y: 0,
    },
  }));
  return (
    <IconContainter>
      <Icon $url={props.$url} ref={ref} style={springs} />
      <animated.div ref={refText} style={springsText}>
        <p>{props.text}</p>
      </animated.div>
    </IconContainter>
  );
}

export function Rules() {
  const { data } = usePadelRulesQuery();
  return (
    <>
      <TopTitle>PADEL</TopTitle>
      <Title>{data?.padelRules?.titolo}</Title>
      <Container>
        <AnimatedIcon
          $url="rules/campo.webp"
          text={data?.padelRules?.campo || ""}
        />
        <AnimatedIcon
          $url="rules/pallina.webp"
          text={data?.padelRules?.palla || ""}
        />
        <AnimatedIcon
          $url="rules/racchetta.webp"
          text={data?.padelRules?.racchetta || ""}
        />
        <AnimatedIcon
          $url="rules/punti.webp"
          text={data?.padelRules?.punteggio || ""}
        />
      </Container>
    </>
  );
}
