import styled from "styled-components";
import { CustomInput } from "../../components/ContactUs";
import { useContattiQuery } from "../../generated/graphql";
import { GoldButton } from "../../components/GoldButton";

const ContactFormContainer = styled.div`
  flex: 2;
  max-width: 600px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  align-items: start;

  @media screen and (max-width: 900px) {
    justify-items: center;
  }
  h2 {
    grid-column: 1/3;
  }
  p {
    text-align: left;
    grid-column: 1/3;
    font-weight: 300;
    height: fit-content;
    margin: 0;
    justify-self: start;
  }
  button {
    justify-self: baseline;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  input[type="checkbox"] {
    height: 1.2rem;
    width: 1.2rem;
    margin: 0;
    margin-right: 0.3rem;
  }
  label {
    font-weight: 300;
  }
`;
const CheckBoxesContainer = styled.div`
  grid-column: 1/3;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: fit-content;
  flex-wrap: wrap;
  justify-self: baseline;
`;

const LeftField = styled(CustomInput)`
  grid-column: 1 / 2;

  @media screen and (max-width: 900px) {
    grid-column: 1 / 3;
    justify-self: auto;
    max-width: 838px;
  }
`;
const RightField = styled(CustomInput)`
  grid-column: 2 / 3;

  @media screen and (max-width: 900px) {
    grid-column: 1 / 3;
    justify-self: auto;
    max-width: 838px;
  }
`;

const MessageField = styled.textarea`
  grid-column: 1 / 3;
  height: 100px;

  border: 1px solid #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-indent: 1rem;
  width: 100%;
  &:focus {
    outline-color: #a48559;
  }
  &::placeholder {
    color: #000;
    font-family: Montserrat;

    font-size: 1rem;
    font-style: normal;
    font-weight: 100;
    padding-top: 0.1rem;
  }
`;
export function ContactForm() {
  const { data } = useContattiQuery();
  const RegCheckBox = (props: { name: string }) => {
    return (
      <CheckBoxContainer>
        <input type="checkbox" id={props.name}></input>
        <label htmlFor={props.name}>{props.name}</label>
      </CheckBoxContainer>
    );
  };
  return (
    <ContactFormContainer>
      <h2>{data?.contatti?.testoContattaci}</h2>
      <LeftField placeholder="Nome"></LeftField>
      <RightField placeholder="Cognome"></RightField>
      <LeftField placeholder="Email" type="email"></LeftField>
      <RightField placeholder="Telefono" type="tel"></RightField>
      <p>Mi interessa:</p>
      <CheckBoxesContainer>
        <RegCheckBox name="Padel" />
        <RegCheckBox name={data?.servizi?.bistrotERistoranteTitolo || ""} />
        <RegCheckBox name={data?.servizi?.palestraTitolo || ""} />
        <RegCheckBox name="Coworking" />
        <RegCheckBox
          name={data?.servizi?.fisioterapistaEOsteopataTitolo || ""}
        />
        <RegCheckBox name="Piscina" />
        <RegCheckBox name="Hotel" />
      </CheckBoxesContainer>

      <MessageField placeholder="Messaggio" />

      <CheckBoxesContainer>
        <RegCheckBox name={data?.contatti?.testoAccettoPrivacy || ""} />
        <a href="https://www.iubenda.com/privacy-policy/21747366">
          Privacy policy
        </a>
      </CheckBoxesContainer>

      <GoldButton>Invia</GoldButton>
      <p style={{ fontSize: "smaller" }}>{data?.contatti?.gdpr || ""}</p>
    </ContactFormContainer>
  );
}
