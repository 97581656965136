import styled from "styled-components";
import { useIconeQuery } from "../../generated/graphql";
const Container = styled.div`
  display: flex;
  background-color: var(--dark-grey);
  padding: 1em;
  justify-content: center;
  gap: 2em;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;
const GoldContainer = styled(Container)`
  background-color: var(--gold);
  color: white;
`;
const IconFlex = styled.div`
  display: flex;

  flex-direction: row;
  height: 60px;
  align-items: center;
  grid-column-gap: 10px;
  img {
    height: 40px;

    object-fit: contain;
    object-position: center;
  }
`;
function IconBlock(props: { iconImg?: string; text: string }) {
  return (
    <IconFlex>
      {props.iconImg && <img src={props.iconImg} alt="icon" />}

      <p>{props.text}</p>
    </IconFlex>
  );
}

export function HotelServicesRow() {
  const { data } = useIconeQuery();
  return (
    <Container>
      <IconBlock text={data?.hotelPage?.serviziSuRichiesta || ""} />
      <IconBlock
        text={data?.icone?.stanzeComunicanti || ""}
        iconImg="/hotelImg/smallIcons/keys.webp"
      />
      <IconBlock
        text={data?.icone?.transferAeroporto || ""}
        iconImg="/hotelImg/smallIcons/transport.webp"
      />
    </Container>
  );
}

export function PoolServicesRow() {
  const { data } = useIconeQuery();
  return (
    <Container>
      <IconBlock
        text={data?.icone?.lettini || ""}
        iconImg="/hotelImg/smallIcons/poolBed.webp"
      />
      <IconBlock text="10 Day bed" iconImg="/hotelImg/smallIcons/bigBed.webp" />
      <IconBlock
        text={data?.icone?.teliSpiaggia || ""}
        iconImg="/hotelImg/smallIcons/towel.webp"
      />

      <IconBlock
        text={data?.icone?.barBistrotERistorante || ""}
        iconImg="/hotelImg/smallIcons/restaurant.webp"
      />

      <IconBlock
        text={data?.icone?.eventi || ""}
        iconImg="/hotelImg/smallIcons/calendar.webp"
      />
    </Container>
  );
}

export function CheckinRow(props: { text?: string }) {
  return (
    <GoldContainer>
      <IconBlock
        text={props.text || ""}
        iconImg="/hotelImg/smallIcons/reception.webp"
      />
    </GoldContainer>
  );
}
