import React from "react";
import { TopBar } from "./components/Topbar";

import { Resort } from "./pages/resort/Resort";

import { Padel } from "./pages/padel/Padel";
import { Restaurant } from "./pages/restaurant/restaurant";
import { Servizi } from "./pages/servizi/servizi";
import { Press } from "./pages/press/press";
import { Contatti } from "./pages/contatti/contatti";
import { Hotel } from "./pages/hotel/Hotel";
import { Pool } from "./pages/pool/pool";
import { HotelRoom } from "./pages/hotel/hotelRoom";
import { WifiPage } from "./pages/wifi/wifipage";

function App() {
  const pathname = window.location.pathname;
  const domain = window.location.hostname;
  console.log(window.location);
  if (domain === "itiglirestaurant.it" && pathname === "/") {
    window.location.href = "http://itiglirestaurant.it/restaurant";
  }
  console.log(pathname);
  if (pathname === "/wifi/") {
    return <WifiPage />;
  }
  return (
    <>
      <TopBar />

      {pathname === "/padel" && <Padel />}
      {pathname === "/" && <Resort />}
      {pathname === "/resort" && <Resort />}
      {pathname === "/restaurant" && <Restaurant />}
      {pathname === "/servizi" && <Servizi />}
      {pathname === "/stampa" && <Press />}
      {pathname === "/contatti" && <Contatti />}
      {pathname === "/hotel" && <Hotel />}
      {pathname === "/pool" && <Pool />}
      {pathname === "/hotelroom" && <HotelRoom />}
    </>
  );
}

export default App;
