import { animated, useInView } from "@react-spring/web";
import { ReactElement } from "react";
import styled from "styled-components";
import { GoldButton } from "./GoldButton";
import { useServiziQuery } from "../generated/graphql";

const Container = styled.div`
  background-color: black;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  & h1 {
    font-size: 2;
    padding: 30px;
    font-weight: 500;
  }
  & button {
    margin: 40px;
    &:hover {
      color: black;
      background-color: white;
    }
  }
`;

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25vw));
  grid-auto-rows: calc(25vw);
  background-color: black;
  grid-auto-flow: dense;

  color: white;

  @media screen and (min-width: 1150px) {
    & > div:nth-child(16n + 3) {
      grid-column: 4;
    }
    & > div:nth-child(16n + 4) {
      grid-column: 3;
    }
    & > div:nth-child(16n + 1) {
      grid-column: 2;
    }
    & > div:nth-child(16n + 2) {
      grid-column: 1;
    }
  }
  @media screen and (max-width: 1150px) and (min-width: 500px) {
    grid-template-columns: repeat(2, calc(50vw));
    grid-auto-rows: calc(50vw);
    & > div:nth-child(4n + 1) {
      grid-column: 2;
    }
    & > div:nth-child(4n + 2) {
      grid-column: 1;
    }
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, calc(100vw));
    grid-auto-rows: calc(100vw);
  }

  & > div {
    padding: 10px;
  }
`;
const DescriptiveBlock = styled.div<{ $graident: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 0.9em;
  font-weight: 200;
  gap: 13px;
  justify-content: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
    bottom: 0;
    left: 0;
    background: ${(props) => props.$graident};
  }
  & > p {
    max-width: 400px;
  }
`;
const DescImg = styled.div<{ $url: string }>`
  background-image: ${(props) => `url(${props.$url})`};
  height: 100px;
  width: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const ImgCover = styled(animated.div)<{ $url: string }>`
  background-image: ${(props) => `url(${props.$url})`};

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
const Title = styled.div`
  font-weight: 600;
`;

export function ServicesImgPair(props: {
  descImg: string;
  title: string;
  desc: string;
  imgCover: string;
  graident: string;
}) {
  const [ref, springs] = useInView(() => ({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 1000,

    config: { duration: 1500 },
  }));

  return (
    <>
      <DescriptiveBlock $graident={props.graident}>
        <DescImg $url={props.descImg} />
        <Title>{props.title}</Title>
        <p>{props.desc}</p>
      </DescriptiveBlock>
      <ImgCover $url={props.imgCover} ref={ref} style={springs} />
    </>
  );
}

export function ServicesContainer(props: { children: ReactElement }) {
  const { data } = useServiziQuery();
  return (
    <Container>
      <h1>{data?.servizi?.titolo}</h1>
      <ContainerGrid>{props.children}</ContainerGrid>
      <a href="/servizi">
        <GoldButton>{data?.servizi?.testoBottone}</GoldButton>
      </a>
    </Container>
  );
}
