import { Footer, ReducedFooter } from "../../components/Footer";
import { MainSlide, SlideScritta } from "../../components/MainSlide";
import { ContactFormFlex } from "./contactBlock";
import { ContactsServices } from "./contactsServices";
import { ContactsMap } from "./map";

export function Contatti() {
  return (
    <>
      <SlideScritta
        signUrl="/scrittavideo.webp"
        bgUrl="topPics/contact.webp"
        position="center"
      />
      <ContactFormFlex />
      <ContactsServices />
      <ContactsMap />
      <ReducedFooter />
    </>
  );
}
