import styled from "styled-components";
import { Expiriences } from "./expiriences";
import { useWindowSize } from "@uidotdev/usehooks";
import { useEffect } from "react";
import React from "react";
import { useDrag } from "@use-gesture/react";
import { createStorefrontApiClient } from "@shopify/storefront-api-client";
import { useTraduzioniQuery } from "../../generated/graphql";

const FlexContainer = styled.div`
  display: flex;

  flex-direction: row;

  overflow: hidden;
  width: fit-content;
`;
const Scroller = styled.div`
  width: 100%;
  overflow: hidden;
`;
const OuterContainer = styled.div`
  display: grid;

  background-color: var(--grey);
  padding: 2rem 0;
  padding-top: 8rem;
  @media screen and (max-width: 800px) {
    padding-top: 6rem;
  }
  flex-direction: column;
  align-items: center;
  justify-items: center;
  position: relative;
`;

const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 2rem;
  & > div {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    user-select: none; /* Standard syntax */

    background-color: #d9d9d9;
    cursor: pointer;
    &.active {
      background-color: #747272;
    }
  }
`;
const TitleContainer = styled.div`
  position: absolute;
  padding-right: 2rem;
  top: 0px;
  p {
    margin-top: 0;
  }
  h1 {
    margin-bottom: 0;
  }
`;
const Note = styled.p`
  padding: 0 2rem;
  text-align: left;
  font-size: 0.7rem;
`;
function Title() {
  const { data } = useTraduzioniQuery();
  return (
    <TitleContainer>
      <h1>{data?.traduzioniVarie?.esperienze}</h1>
      <p>{data?.traduzioniVarie?.esperienzeSottoTitolo}</p>
    </TitleContainer>
  );
}

export function FixedContainer(props: { children: React.ReactNode }) {
  const size = useWindowSize();
  const width = size.width;
  return (
    <FlexContainer>
      <div style={{ width: width || 0 }}>{props.children}</div>
    </FlexContainer>
  );
}

export function ExpiriencesScroller() {
  const size = useWindowSize();
  const width = size.width;
  const nrElemnts = 1;
  const [selected, setSelected] = React.useState(0);
  function ScrollDirection(direction: number) {
    if (direction < 0) {
      setSelected(selected + 1 < nrElemnts ? selected + 1 : selected);
    }
    if (direction > 0) {
      console.log(selected - 1);
      setSelected(selected - 1 >= 0 ? selected - 1 : selected);
      console.log(selected);
    }
  }
  const bind = useDrag(
    ({ down, first, direction }) => {
      down && first && ScrollDirection(direction[0]);
    },

    {
      axis: "x",
    }
  );

  useEffect(() => {
    document
      ?.getElementById("scroller")
      ?.scrollTo({ left: selected * (width || 0), behavior: "smooth" });
  }, [selected]);

  useEffect(() => {
    document
      ?.getElementById("scroller")
      ?.scrollTo({ left: selected * (width || 0) });
  }, [width]);
  return (
    <OuterContainer>
      <Scroller id="scroller" {...bind()}>
        <FlexContainer>
          <FixedContainer>
            <Expiriences
              productId="48914696012063"
              title={<Title />}
              id="7u2H6zvmuf7yPpWzoB9cDU"
              img="/hotelImg/esperienze/resort.webp"
            />
          </FixedContainer>
          {/*      <FixedContainer>
            <Expiriences
              img="/hotelImg/esperienze/sportiva.webp"
              id="7m4TvyYsbmRcBbKpls1w6c"
              productId="48858655719711"
            />
          </FixedContainer>
          <FixedContainer>
            <Expiriences
              productId="48858655752479"
              img="/hotelImg/esperienze/romantica.webp"
              id="1FAImYubAR1vYIHNf2s4ae"
            />  </FixedContainer>*/}
        </FlexContainer>
      </Scroller>
      <DotContainer>
        {Array.from({ length: nrElemnts }).map((_, index) => (
          <div
            key={index}
            className={selected === index ? "active" : ""}
            onClick={() => setSelected(index)}
          />
        ))}
      </DotContainer>
    </OuterContainer>
  );
}
