import { useEffect, useState } from "react";
import { ContactUsStandard } from "../../components/ContactUsStandard";
import { Footer } from "../../components/Footer";
import { IconsRow } from "../../components/IconsRow";
import { VideoRestaurant } from "../../components/MainSlide";
import { Partner } from "../../components/Partner";
import { ApiScroller, Scroller } from "../../components/Scroller";
import {
  ServicesContainer,
  ServicesImgPair,
} from "../../components/Servicesnew";
import { TopDescription } from "../../components/TopDescription";
import { WorkWithUs } from "../../components/WorkWithUs";
import { useServiziQuery } from "../../generated/graphql";
import { Filosofia } from "./filosofia";
import { ChefDesc, LocationDesc } from "./textImageBlock";
function ServicesRestaurant() {
  const { data } = useServiziQuery();
  return (
    <ServicesContainer>
      <>
        <ServicesImgPair
          descImg="loghiServices/padel.webp"
          title={data?.servizi?.padelArenaTitolo || ""}
          imgCover="imgServices/padel.webp"
          desc={data?.servizi?.padelArena || ""}
          graident="linear-gradient(90deg, rgba(164, 121, 57, 1) 0%, rgba(253, 212, 145, 1) 24.93%, rgba(164, 121, 57, 1) 50.94%, rgba(253, 212, 145, 1) 75.82%, rgba(164, 121, 57, 1) 100%)"
        />
        <ServicesImgPair
          descImg="loghiServices/work.webp"
          title={data?.servizi?.coworkingTitolo || ""}
          desc={data?.servizi?.coworking || ""}
          imgCover="imgServices/work.webp"
          graident="linear-gradient(90deg, rgba(136, 31, 48, 1) 0%, rgba(136, 31, 48, 1) 0.21%, rgba(233, 79, 53, 1) 70.11%, rgba(233, 79, 53, 1) 100%)"
        />
        <ServicesImgPair
          descImg="loghiServices/pale.webp"
          title={data?.servizi?.palestraTitolo || ""}
          desc={data?.servizi?.palestra || ""}
          imgCover="imgServices/pale.webp"
          graident="linear-gradient(90deg, rgba(189, 85, 32, 1) 0%, rgba(189, 85, 32, 1) 0.11%, rgba(255, 209, 89, 1) 70%, rgba(255, 209, 89, 1) 100%)"
        />
        <ServicesImgPair
          descImg="loghiServices/fisio.webp"
          title={data?.servizi?.fisioterapistaEOsteopataTitolo || ""}
          imgCover="imgServices/fisio.webp"
          desc={data?.servizi?.fisioterapistaEOsteopata || ""}
          graident="linear-gradient(
          90deg,
          rgba(129, 73, 56, 1) 0%,
          rgba(129, 73, 56, 1) 0.38%,
          rgba(243, 151, 120, 1) 70.28%,
          rgba(243, 151, 120, 1) 100%
        )"
        />
      </>
    </ServicesContainer>
  );
}

export function adatta(evt: { data: string }) {
  var offsetH = 0;
  if (false) {
  } else {
    var iFrameID = document.getElementById("iFramePrenota");
    if (iFrameID) {
      iFrameID.style.border = "0";
      iFrameID.style.width = "100%";
      iFrameID.style.marginBottom = "1500px";
      var myHeight = evt.data.toString();
      if (evt.data == "top") {
        var mytop =
          iFrameID.getBoundingClientRect().top +
          window.pageYOffset -
          iFrameID.ownerDocument.documentElement.clientTop -
          offsetH;
        document.documentElement.scrollTop = mytop;
        iFrameID.style.marginBottom = "0";
      } else if (myHeight.substring(0, 6) == "middle") {
        var mytop =
          iFrameID.getBoundingClientRect().top +
          window.pageYOffset -
          iFrameID.ownerDocument.documentElement.clientTop -
          offsetH;
        iFrameID.style.height = window.innerHeight + "px";
        iFrameID.style.marginBottom = "0";
        window.scrollTo(0, mytop);
      } else {
        iFrameID.style.height = evt.data + "px";
        iFrameID.style.marginBottom = "0";
      }
    }
  }
}

export function Restaurant() {
  const [isPrenota, setPrenota] = useState<"ristorante" | "bistrot" | boolean>(
    false
  );
  useEffect(() => {
    if (window.addEventListener) {
      // For standards-compliant web browsers
      window.addEventListener("message", adatta, false);
    }
  });

  function scrollPrenota() {
    var bookId = document.getElementById("book");
    if (bookId) {
      var mytop =
        bookId.getBoundingClientRect().top -
        120 -
        bookId.ownerDocument.documentElement.clientTop;
      document.documentElement.scrollTop = mytop;
    }
  }
  const lang = localStorage.getItem("lang") || "it";

  return (
    <>
      <VideoRestaurant onClick={scrollPrenota}></VideoRestaurant>

      <TopDescription id="4ClOyeEeoyjrCRizcjoleT" />
      <Filosofia setPrentota={setPrenota} />
      <div>
        {isPrenota && (
          <iframe
            title="Prenota"
            src={
              `https://thepadelresort.prenota-web.it/prenotazioni/?iframe=1&lang=${lang}` +
              (isPrenota === "ristorante" ? "" : "&ristorante=bistrot")
            }
            allowFullScreen
            style={{ border: "none", width: "100%", height: "1000px" }}
            id="iFramePrenota"
          ></iframe>
        )}
      </div>
      <IconsRow />
      <LocationDesc />

      <ChefDesc />
      <ServicesRestaurant />
      <ContactUsStandard />
      <ApiScroller id="7vGsBjhozb5q1dA5iNDRem" />

      <Partner />
      <WorkWithUs />
      <Footer
        number="+390318180880"
        email="prenotazioni@itiglirestaurant.it"
        prettyNumber="+39 031 8180 880"
      />
    </>
  );
}
