import styled from "styled-components";
import { GoldButton } from "../../components/GoldButton";
import { usePoolQuery, useTraduzioniQuery } from "../../generated/graphql";
import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-template-rows: auto auto auto auto auto;
  grid-column-gap: 2rem;
  flex: 1;

  padding: 2rem;
  justify-content: center;
`;

const EventContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-row: span 5;
  grid-template-rows: subgrid;

  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  margin-bottom: 2rem;
  h2 {
    color: var(--gold);
    font-size: 1.5em;
    margin-bottom: 0;
    text-align: center;
  }
  & img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  & h1 {
    color: var(--gold);
    text-align: center;
  }
`;

const BookLink = styled.a`
  width: fit-content;
  justify-self: center;
`;
function formatItalianDate(date: Date): string {
  const months = [
    "gennaio",
    "febbraio",
    "marzo",
    "aprile",
    "maggio",
    "giugno",
    "luglio",
    "agosto",
    "settembre",
    "ottobre",
    "novembre",
    "dicembre",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day} ${month} ${year} - ore ${hours}:${minutes}`;
}
function Event(props: {
  text?: React.ReactNode;
  img?: string;
  date: Date;
  title?: string;
  link?: string;
}) {
  const trad = useTraduzioniQuery();
  return (
    <EventContainer>
      <img src={props.img + "?fm=webp&w=700"} alt={props.title}></img>
      <div>{formatItalianDate(props.date)}</div>
      <h2>{props.title}</h2>
      {props.text}
      <BookLink href={props.link}>
        <GoldButton>{trad.data?.traduzioniVarie?.prenota}</GoldButton>
      </BookLink>
    </EventContainer>
  );
}

export function PoolEvents() {
  const { data } = usePoolQuery({
    variables: { now: new Date().toISOString() },
  });
  const events = data?.eventoPiscinaCollection?.items;
  console.log(events);
  if (!events) return null;
  return (
    <FlexContainer>
      <h1>SUMMER EVENTS</h1>
      <Grid>
        {events?.map((event) => (
          <Event
            key={event?.titolo}
            title={event?.titolo || ""}
            date={new Date(event?.data)}
            text={documentToReactComponents(event?.descrizione?.json)}
            link={event?.prenotaLInk || ""}
            img={event?.foto?.url || ""}
          />
        ))}
      </Grid>
    </FlexContainer>
  );
}
