import styled from "styled-components";
import { Articles } from "../press/articlesGrid";
import { GoldButton } from "../../components/GoldButton";
import { useWindowSize } from "@uidotdev/usehooks";
const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: #9f865f;
  text-align: center;
`;
const Container = styled.div`
  background-color: #f8f7f7;
  padding: 40px 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  & button {
    background-color: transparent;
    border: 2px solid #9f865f;
    color: #9f865f;
  }
`;

function nrOfArticles(width: number) {
  let result = 1;
  if (width > 800) result = 2;
  if (width > 1000) result = 3;
  if (width > 1400) result = 4;
  if (width > 2200) result = 5;
  return result;
}
export function ArticlesBlock() {
  const size = useWindowSize();
  console.log("xiao");
  return (
    <Container>
      <Title>NEWS E MEDIA</Title>
      <Articles limit={(size.width && nrOfArticles(size.width)) || 1} />;
      <ButtonContainer>
        <a href="/stampa">
          <GoldButton>Scopri di più</GoldButton>
        </a>
      </ButtonContainer>
    </Container>
  );
}
