import { ContactUs } from "../../components/ContactUs";
import { ContactUsStandard } from "../../components/ContactUsStandard";
import { Footer } from "../../components/Footer";
import { IconsRow } from "../../components/IconsRow";
import { SlideScritta, VideoSlide } from "../../components/MainSlide";
import { Partner } from "../../components/Partner";
import { ApiScroller, Scroller } from "../../components/Scroller";
import { TopDescription } from "../../components/TopDescription";
import { WorkWithUs } from "../../components/WorkWithUs";
import { useContattiQuery } from "../../generated/graphql";
import { ServiceDescList } from "./serviceDescList";

function ContactUsServices() {
  const { data } = useContattiQuery();
  return (
    <ContactUs
      blackTheme={true}
      text={data?.contatti?.iscrivitiAlClub || ""}
      url="https://thepadelresort.us14.list-manage.com/subscribe/post?u=fc95bebb5e17453f8c556fef9&amp;id=ff6e75225b&amp;f_id=003fafe0f0"
    />
  );
}
export function Servizi() {
  return (
    <>
      <VideoSlide
        logo="/scrittavideo.webp"
        videoUrl="https://tiglivideo.b-cdn.net/servizi.mp4"
      />
      <TopDescription id="pdMBmOIVpZgYjgAoMK1tO" />
      <ServiceDescList></ServiceDescList>
      <IconsRow />
      <TopDescription id="5Hk4gFl3VCuN4Q16TWcBWn" />
      <ApiScroller id="38KtM1DXgpJlXKEuiMnoXw" />

      <ContactUsStandard />
      <ContactUsServices />
      <Partner />
      <WorkWithUs />
      <Footer
        number="+390318180860"
        email="reception@thepadelresort.it"
        prettyNumber="+39 031 8180 860"
      />
    </>
  );
}
