import { useState } from "react";
import styled from "styled-components";
import { useTraduzioniQuery } from "../generated/graphql";
const TopbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: center;
  align-content: normal;
  background-color: black;
  padding: 15px;
  position: relative;
  font-style: normal;
  font-weight: 200;
  font-size: 0.95em;
  position: fixed;
  z-index: 1000;
  inset: 0;
  color: white;
  height: 80px;
  transition: height 500ms;

  &.open {
    height: 100vh;
  }
  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: flex-start;
  }
`;
const Option = styled.button<{ $coming?: boolean }>`
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  text-align: center;
  text-transform: uppercase;
  background: none;
  color: inherit;
  border: none;
  position: relative;
  padding: 0;

  &::after {
    content: "COMING SOON";
    display: block;

    width: 100%;
    height: 2px;
    color: #9f865f;
    font-size: 0.7em;
    text-align: center;
    opacity: ${(props) => `${props.$coming ? "1" : "0"}`};
  }
  font: inherit;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const Logo = styled.div`
  background-image: url(/logotop.webp);
  background-repeat: no-repeat;
  background-size: 150px;
  height: 83px;
  z-index: 1000;
  width: 150px;
`;
const LangFlag = styled.div`
  z-index: 1000;

  font-size: 26px;
  cursor: pointer;

  text-align: center;
  @media screen and (max-width: 900px) {
    position: absolute;
    text-align: center;
    right: 30px;
    top: 36px;
  }
`;
const OpenMenu = styled.div`
  width: 50px;
  height: 15px;
  border: 5px solid white;
  border-left: none;
  border-right: none;
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 2;
  @media screen and (min-width: 900px) {
    display: none;
  }
`;
const HamburgerMenu = styled.div`
  position: absolute;
  background-color: black;
  height: 100%;
  width: 100%;
  top: 150px;
  left: 50px;
  opacity: 0;
  & > div {
    text-transform: uppercase;
    font-size: 2em;
    margin-bottom: 20px;
  }
  transition: opacity 300ms;
  display: none;
  &.open {
    opacity: 1;
    display: block;
  }
`;

const TopRibon = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  background-color: #a48559;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 0.8em;
  align-items: center;
  justify-content: center;
`;
function TopBar() {
  const [menu, setMenu] = useState(false);
  function ChangeLang(lang: "en" | "it") {
    localStorage.setItem("lang", lang);
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }
  const { data } = useTraduzioniQuery();
  const lang = localStorage.getItem("lang") || "it";
  return (
    <>
      <TopbarContainer className={menu ? "open" : ""}>
        <HamburgerMenu className={menu ? "open" : ""}>
          <div>
            <a href="/padel">{data?.traduzioniVarie?.padel}</a>
          </div>
          <div>
            <a href="http://itiglirestaurant.it/restaurant">
              {data?.traduzioniVarie?.ristorante}
            </a>
          </div>
          <div>
            <a href="/hotel">{data?.traduzioniVarie?.hotel}</a>
          </div>
          <div>
            <a href="/pool">{"piscina"}</a>
          </div>
          <div>
            <a href="/servizi">{data?.traduzioniVarie?.servizi}</a>
          </div>
          <div>
            <a href="/contatti">{data?.traduzioniVarie?.contatti}</a>
          </div>
        </HamburgerMenu>

        <OpenMenu onClick={() => setMenu(!menu)} />

        <a href="/resort">
          <Logo />
        </a>
        <Option>
          <a href="/padel">{data?.traduzioniVarie?.padel}</a>
        </Option>

        <Option>
          <a href="http://itiglirestaurant.it/restaurant">
            {data?.traduzioniVarie?.ristorante}
          </a>
        </Option>
        <Option>
          <a href="/hotel">{data?.traduzioniVarie?.hotel}</a>
        </Option>
        <Option>
          <a href="/pool">{data?.traduzioniVarie?.piscina}</a>
        </Option>

        <Option>
          <a href="/servizi">{data?.traduzioniVarie?.servizi}</a>
        </Option>
        <Option>
          <a href="/contatti">{data?.traduzioniVarie?.contatti}</a>
        </Option>
        {lang === "it" && (
          <LangFlag onClick={() => ChangeLang("en")}>🇬🇧</LangFlag>
        )}
        {lang === "en" && (
          <LangFlag onClick={() => ChangeLang("it")}>🇮🇹</LangFlag>
        )}
      </TopbarContainer>
    </>
  );
}

export { TopBar };
