import React from "react";
import styled from "styled-components";
import { CustomInput } from "../../components/ContactUs";
import { useForm, SubmitHandler } from "react-hook-form";
import { GoldButton } from "../../components/GoldButton";

type Inputs = {
  email: string;
  name: string;
  surname: string;
};

export function WifiPage() {
  const [done, setDone] = React.useState(false);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await fetch("/.netlify/functions/wifiAccess", {
      method: "POST",

      body: JSON.stringify(data),
    }).then(() => setDone(true));
  };
  if (done) {
    return (
      <Container>
        <Logo></Logo>
        Grazie!
      </Container>
    );
  }
  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Logo />
      <p>
        <b>Free Wifi Access - The Padel Resort</b>
      </p>
      <CustomInput placeholder="Nome" {...register("name")} required />
      <CustomInput placeholder="Cognome" {...register("surname")} required />
      <CustomInput
        type="email"
        placeholder="Email"
        {...register("email")}
        required
      />
      Dichiaro di aver letto e accettato le condizioni della Privacy policy.
      <GoldButton type="submit">Invia</GoldButton>
    </Container>
  );
}

const Container = styled.form`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
const Logo = styled.div`
  background-image: url(/logowhitebg.webp);
  background-repeat: no-repeat;
  background-size: 150px;
  height: 83px;
  z-index: 1000;
  width: 150px;
`;
