import { ContactUs } from "./ContactUs";
import { useContattiQuery } from "../generated/graphql";
export function ContactUsStandard() {
  const { data } = useContattiQuery();
  return (
    <ContactUs
      text={data?.contatti?.testoIscriviti || ""}
      url="https://thepadelresort.us14.list-manage.com/subscribe/post?u=fc95bebb5e17453f8c556fef9&amp;id=ff6e75225b&amp;f_id=003fafe0f0"
    />
  );
}
