import styled from "styled-components";
import { GoldTitle } from "./GoldTitle";
import { GoldButton } from "./GoldButton";
import { useContactUsQuery } from "../generated/graphql";
const Container = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  align-items: center;
  text-align: center;
`;

const Text = styled.p`
  max-width: 800px;
  word-wrap: break-word;
  white-space: pre-line;
`;

export function WorkWithUs() {
  const { data } = useContactUsQuery({});
  return (
    <Container>
      <GoldTitle>{data?.lavoraConNoi?.titolo}</GoldTitle>

      <Text>{data?.lavoraConNoi?.testo}</Text>
      <a href="mailto:info@thepadelresort.it">
        <GoldButton>{data?.lavoraConNoi?.invia}</GoldButton>
      </a>
    </Container>
  );
}
