import styled from "styled-components";
import { GoldButton } from "./GoldButton";
import { useTraduzioniQuery } from "../generated/graphql";
export const MainSlide = styled.div<{ $url: string; $position?: string }>`
  background-image: ${(props) => `url(${props.$url})`};
  margin-top: 100px;
  background-repeat: no-repeat;
  background-position: ${(props) => props.$position || "top"};
  background-size: cover;
  height: 700px;

  @media screen and (max-width: 900px) {
    height: 300px;
  }
`;

const Video = styled.video`
  margin-top: 110px;
  width: 100%;
  pointer-events: none;
  height: auto;
  display: block;
`;
const VideoContainer = styled.div`
  position: relative;

  margin: 0 auto;
  margin-top: 100px;
  top: 10px;
`;
const VideoOverlay = styled.div`
  content: "";
  position: absolute;

  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 40px;
`;

const VideoLogo = styled.div<{ $url: string }>`
  width: 70%;
  height: 50%;
  background-image: ${(props) => `url(${props.$url})`};
  background-repeat: no-repeat;
  background-size: contain;
  max-height: 350px;
`;

export function MainSlideVideo() {
  return (
    <VideoContainer style={{ top: 0 }}>
      <VideoOverlay>
        <VideoLogo $url="/scrittavideo.webp" />
      </VideoOverlay>
      <Video autoPlay muted loop playsInline>
        <source
          src="https://tiglivideo.b-cdn.net/video_home.mp4"
          type="video/mp4"
        />
        <source
          src="https://tiglivideo.b-cdn.net/video_home_compatible.mp4"
          type="video/mp4"
        />
      </Video>
    </VideoContainer>
  );
}
export function VideoRestaurant(props: { onClick?: () => void }) {
  const { data } = useTraduzioniQuery();
  return (
    <VideoContainer style={{ top: 0 }}>
      <VideoOverlay>
        <VideoLogo $url="/scriresto.webp" />
        <br></br>
        <GoldButton onClick={props.onClick}>
          {data?.traduzioniVarie?.prenota}
        </GoldButton>
      </VideoOverlay>
      <Video autoPlay muted loop playsInline>
        <source
          src="https://tiglivideo.b-cdn.net/video_bistrot.mp4"
          type="video/mp4"
        />
      </Video>
    </VideoContainer>
  );
}
export function VideoSlide(props: {
  onClick?: () => any;
  logo?: string;
  videoUrl: string;
}) {
  const { data } = useTraduzioniQuery();
  return (
    <VideoContainer style={{ top: 0 }}>
      <VideoOverlay>
        {props.logo && <VideoLogo $url={props.logo} />}
        <br></br>
        {props.onClick && (
          <GoldButton onClick={props.onClick}>
            {data?.traduzioniVarie?.prenota}
          </GoldButton>
        )}
      </VideoOverlay>
      <Video autoPlay muted loop playsInline>
        <source src={props.videoUrl} type="video/mp4" />
      </Video>
    </VideoContainer>
  );
}

export function SlideScritta(props: {
  bgUrl: string;
  signUrl: string;
  prenota?: boolean;
  position?: string;
  onlick?: () => void;
}) {
  const { data } = useTraduzioniQuery();
  return (
    <>
      <VideoContainer>
        <VideoOverlay>
          <VideoLogo $url={props.signUrl} />
          <br></br>
          {props.prenota && (
            <GoldButton onClick={props.onlick}>
              {data?.traduzioniVarie?.prenota}
            </GoldButton>
          )}
        </VideoOverlay>
        <MainSlide $url={props.bgUrl} $position={props.position || "top"} />
      </VideoContainer>
    </>
  );
}
